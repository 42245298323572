import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Checkbox,
  Label,
  Modal,
  Select,
  Spinner,
  TextInput,
  Textarea,
} from "flowbite-react";
import { useEffect, useState } from "react";
import { GET_ORDER_BY_PK, GET_PRODUCT_SETS, UPDATE_ORDER_BY_PK } from "../gqls";
import { ADD_RELEASED_ORDERS, GET_RELEASED_ORDERS_CONDITIONAL } from "./gqls";
import { ProductSetOrderPreset, ProductSetType } from "../types";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { GET_TEAMS } from "../../settings/teams/teamList";
import isNullish from "../../../../utils/isNullish";
import { ADD_PRODUCTION } from "../../production/gql";
import { PRODUCTION_CORE_FIELDS } from "../../shared/productions/fragments";
import { fullNumber } from "../../../../utils/fullNumber";
import { push } from "../../../../utils/pushAPI";
import {
  NewProductionType,
  ProductionType,
} from "../../shared/productions/types";
import { GET_USERS } from "../../settings/users/gql";
import { useAuthStore } from "../../../../store/authStore";
import { ReleasedOrderType } from "./types";
import { RELEASED_ORDERS_CORE_FIELDS } from "./fragments";
import { useScheme } from "../../../../store/schemeStore";
import { addAlert } from "../../../../store/alertStore";
import { calc, openingCalc, option } from "../createOrder/types";
import { MaterialsType } from "../../materials/types";
import { all, create } from "mathjs";
import { sort } from "fast-sort";
import usePushOrderStatus from "../orderDetail/usePushOrderStatus";

interface props {
  show: boolean;
  onClose: () => void;
  orderId: number;
}

export default function OrderRelease({ show, onClose, orderId }: props) {
  const { data } = useQuery(GET_PRODUCT_SETS);
  const ProductSets = data?.productSets;

  const math = create(all);

  math.SymbolNode.onUndefinedSymbol = e => {
    // console.log(e);
    return 0;
  };

  const { data: data_order } = useQuery(GET_ORDER_BY_PK, {
    variables: {
      id: orderId,
    },
  });

  const order = data_order?.workorder_by_pk;
  const orderData = order?.order;

  const locations = orderData?.locations.map((l, i) => ({
    ...l,
    index: i + 1,
    openings: l.openings.map((o, ix) => ({
      ...o,
      items: o.items.map((item, iz) => ({ ...item, index: iz + 1 })),
      index: ix + 1,
    })),
  }));

  const openings = locations?.map(l => l.openings).flat();

  const products = openings?.reduce<
    {
      productId: number;
      id: number;
      name: string;
      qty: number;
      openingProduct: boolean;
      presets: ProductSetOrderPreset[];
    }[]
  >((prev, cur) => {
    const ProductSet = ProductSets?.find(p => p.id == cur.product);
    if (!ProductSet) {
      return prev;
    }

    const name = ProductSet?.name;
    const isOpeningProduct = ProductSet.hideItems;

    const openingOptions: option[] = isOpeningProduct ? cur?.options || [] : [];
    const openingScopes = openingOptions?.reduce<any>((prv, cur) => {
      try {
        if (!cur) return prv;
        const scopeName = cur.name.replaceAll(" ", "");
        let appended = {
          ...prv,
          [scopeName]: cur.value,
        };
        if (cur.source == "inventory") {
          const material = cur.populatedValue as MaterialsType;
          if (material) {
            appended = {
              ...appended,
              [`${scopeName}_W`]: isNullish(material.widthDeduction)
                ? 0
                : Number(material.widthDeduction),
              [`${scopeName}_H`]: isNullish(material.heightDeduction)
                ? 0
                : Number(material.heightDeduction),
              [`${scopeName}_Size`]: isNullish(material.size)
                ? 0
                : Number(material.size),
            };
          }
        }
        return appended;
      } catch (error) {
        console.log(error, cur, isOpeningProduct);
        return prv;
      }
    }, {});

    const qty = Number(
      isOpeningProduct
        ? math.evaluate(ProductSet.qtyCalc || "1", openingScopes)
        : cur.items.reduce<number>((prev, cur) => {
            const itemOptions: option[] = cur?.options || [];
            const itemScopes = itemOptions?.reduce<any>((prv, cur) => {
              if (!cur) return prv;
              const scopeName = cur.name.replaceAll(" ", "");
              let appended = {
                ...prv,
                [scopeName]: cur.value,
              };
              if (cur.source == "inventory") {
                const material = cur.populatedValue as MaterialsType;
                if (material) {
                  appended = {
                    ...appended,
                    [`${scopeName}_W`]: isNullish(material.widthDeduction)
                      ? 0
                      : Number(material.widthDeduction),
                    [`${scopeName}_H`]: isNullish(material.heightDeduction)
                      ? 0
                      : Number(material.heightDeduction),
                    [`${scopeName}_Size`]: isNullish(material.size)
                      ? 0
                      : Number(material.size),
                  };
                }
              }
              return appended;
            }, {});
            return prev + math.evaluate(ProductSet.qtyCalc || "1", itemScopes);
          }, 0)
    );

    if (prev.find(p => p.name == name)) {
      return prev.map(pro => {
        if (pro.name == name) {
          return { ...pro, qty: pro.qty + qty };
        } else return pro;
      });
    } else {
      return prev.concat({
        id: ProductSet.id,
        name,
        qty,
        openingProduct: ProductSet.hideItems,
        presets: ProductSet.orderPresets || [],
        productId: ProductSet.product.id,
      });
    }
  }, []);

  const { data: data_releasedOrders } = useQuery(
    GET_RELEASED_ORDERS_CONDITIONAL,
    {
      variables: {
        where: {
          orderId: { _eq: order?.id },
        },
      },
    }
  );

  const releasedOrders = data_releasedOrders?.releasedOrders;

  const defaultValues: {
    selectedPresets: {
      productSetId: number;
      productId: number;
      name: string;
      team?: string;
      due?: string;
      desc?: string;
    }[];
  } = {
    selectedPresets: [],
  };

  const methods = useForm({
    defaultValues,
  });

  const { reset, handleSubmit, control, setValue } = methods;

  const selectedPresets = useWatch({
    control,
    name: "selectedPresets",
  });

  const togglePreset = (
    productSetId: number,
    preset: ProductSetOrderPreset,
    deleting: boolean
  ) => {
    if (deleting) {
      setValue(
        "selectedPresets",
        selectedPresets.filter(
          sp => !(sp.productId == preset.productId && sp.name == preset.name)
        )
      );
    } else {
      setValue(
        "selectedPresets",
        selectedPresets.concat({
          productSetId,
          productId: preset.productId,
          name: preset.name,
        })
      );
    }
  };

  const toggleOpeningPreset = (
    product: {
      productId: number;
      id: number;
      name: string;
      qty: number;
      openingProduct: boolean;
      presets: ProductSetOrderPreset[];
    },
    deleting: boolean
  ) => {
    if (deleting) {
      setValue(
        "selectedPresets",
        selectedPresets.filter(
          sp => !(sp.productId == product.productId && sp.name == product.name)
        )
      );
    } else {
      setValue(
        "selectedPresets",
        selectedPresets.concat({
          productSetId: product.id,
          productId: product.productId,
          name: product.name,
        })
      );
    }
  };

  const [insert_releasedOrder] = useMutation(ADD_RELEASED_ORDERS);
  const [insert_production] = useMutation(ADD_PRODUCTION);
  const [update_order] = useMutation(UPDATE_ORDER_BY_PK);

  const { data: data_users } = useQuery(GET_USERS);
  const users = data_users?.users;

  const { user } = useAuthStore();

  const pushOrderStatus = usePushOrderStatus();

  const [loading, setLoading] = useState(false);
  const submit = handleSubmit(async data => {
    setLoading(true);

    await Promise.all(
      data.selectedPresets.map(async preset => {
        if (!order) {
          return true;
        }
        // add production
        const production: NewProductionType = {
          processID: order.process.id,
          badge: order.orderRef || "",
          createdBy: user?.id || 0,
          description: preset.desc || "",
          due: preset.due && !isNullish(preset.due) ? preset.due : null,
          productID: preset.productId,
          qty: Number(
            products?.find(product => product.id == preset.productSetId)?.qty
          ),
          statusID: 1,
          teamID: Number(preset.team),
        };

        const { data } = await insert_production({
          variables: production,
          update(cache, { data: { insert_productions_one: newProduction } }) {
            cache.modify({
              fields: {
                productions(existingProductions = []) {
                  const newProductionRef = cache.writeFragment({
                    data: newProduction,
                    fragment: PRODUCTION_CORE_FIELDS,
                    fragmentName: "ProductionCoreFields",
                  });
                  return [...existingProductions, newProductionRef];
                },
              },
            });
          },
          onCompleted(res) {
            const production = res.insert_productions_one as ProductionType;
            if (!production) {
              return;
            }

            const process = production.process;
            if (!process) {
              return;
            }

            const ids = users
              ?.filter(
                u =>
                  u.team?.id == production.team.id &&
                  u.auth.name.includes("leader")
              )
              .map(u => u.id);
            if (!ids) {
              return;
            }

            const title = `Order Released - ${
              process
                ? fullNumber(
                    process.processType.prefix,
                    process.year,
                    process.number
                  )
                : ""
            }`;
            const body = `${production.product.name} ${production.qty}`;

            push({
              title,
              body,
              ids,
            });
          },
        });

        const addedProduction = data.insert_productions_one;

        // add releasedOrders
        const orderToRelease: Omit<ReleasedOrderType, "id"> = {
          orderId: order.id,
          presetName: preset.name,
          productionId: addedProduction.id,
          productSetId: preset.productSetId,
        };

        await insert_releasedOrder({
          variables: { object: orderToRelease },
          update(
            cache,
            { data: { insert_releasedOrders_one: newReleasedOrder } }
          ) {
            cache.modify({
              fields: {
                releasedOrders(existingReOrders = []) {
                  const newReOrderRef = cache.writeFragment({
                    data: newReleasedOrder,
                    fragment: RELEASED_ORDERS_CORE_FIELDS,
                    fragmentName: "RelasedOrdersCoreFields",
                  });
                  return [...existingReOrders, newReOrderRef];
                },
              },
            });
          },
        });

        addAlert({
          message: "orders released successfully",
          type: "success",
        });

        return true;
      })
    );

    const newStatId =
      order?.status && order?.status.id > 2 ? order?.status.id : 3;

    await update_order({
      variables: {
        id: order?.id,
        set: {
          statusId: newStatId,
        },
      },
    });

    if (order) {
      pushOrderStatus(order, newStatId);
    }

    setLoading(false);
    reset();
    onClose();
  });

  const { scheme } = useScheme();

  return (
    <Modal
      show={show}
      onClose={onClose}
      className={`${scheme} !h-full !items-start`}
    >
      <FormProvider {...methods}>
        <Modal.Header>Release Order</Modal.Header>
        <Modal.Body>
          <div className={`dark:text-white flex flex-col gap-2`}>
            <h3>Select Order Presets</h3>
            <div className="ml-2 flex flex-col gap-2">
              {products?.map(product => {
                const openingChecked = selectedPresets.find(
                  sp =>
                    sp.productId == product.productId && sp.name == product.name
                )
                  ? true
                  : false;
                const openingReleased = releasedOrders?.find(
                  ro => ro.productSetId == product.id
                );

                return (
                  <div className="flex flex-col gap-2" key={product.id}>
                    <div className="font-semibold">{product.name}</div>
                    <div className="flex flex-row ml-2 gap-2 flex-wrap">
                      {product.presets.map(preset => {
                        const released = releasedOrders?.find(
                          ro =>
                            ro.productSetId == product.id &&
                            ro.presetName == preset.name
                        );

                        const checked =
                          released ||
                          selectedPresets.find(
                            sp =>
                              sp.productId == preset.productId &&
                              sp.name == preset.name
                          )
                            ? true
                            : false;

                        return (
                          <div
                            key={`${preset.productId}${preset.name}`}
                            className="flex flex-row gap-2 items-center cursor-pointer"
                            onClick={() => {
                              togglePreset(product.id, preset, checked);
                            }}
                          >
                            <Label value={preset.name} />
                            <Checkbox
                              className={`checked:bg-plum dark:checked:bg-plum ${
                                released && "grayscale"
                              }`}
                              checked={checked}
                              disabled={released ? true : false}
                              readOnly
                            />
                          </div>
                        );
                      })}
                      {product.openingProduct && (
                        <div
                          className="flex flex-row gap-2 items-center cursor-pointer"
                          onClick={() => {
                            toggleOpeningPreset(product, openingChecked);
                          }}
                        >
                          <Label value={product.name} />
                          <Checkbox
                            className={`checked:bg-plum dark:checked:bg-plum ${
                              openingReleased && "grayscale"
                            }`}
                            checked={
                              openingChecked || openingReleased ? true : false
                            }
                            disabled={openingReleased ? true : false}
                            readOnly
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <hr />
            <h3>Select Teams</h3>
            <div className="ml-2 flex flex-col gap-2">
              {selectedPresets.map((sp, i) => (
                <SelectedPreset
                  preset={sp}
                  index={i}
                  productSets={ProductSets}
                  key={`${sp.productId}${sp.name}`}
                  due={order?.due}
                />
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-end gap-2">
          <Button
            size="sm"
            color="purple"
            onClick={() => {
              reset();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            outline
            size="sm"
            gradientDuoTone="purpleToBlue"
            onClick={submit}
          >
            <div className="flex flex-row gap-2 items-center">
              {loading && <Spinner size="sm" color="purple" />}
              Release
            </div>
          </Button>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  );
}

interface selectedPresetProps {
  preset: {
    productSetId: number;
    productId: number;
    name: string;
  };
  productSets?: ProductSetType[];
  index: number;
  due?: string;
}

const SelectedPreset = ({
  preset,
  productSets,
  index,
  due,
}: selectedPresetProps) => {
  const productSet = productSets?.find(ps => ps?.id == preset.productSetId);
  const { data: data_teams } = useQuery(GET_TEAMS);
  const availableTeams = sort(
    data_teams?.teams.filter(team =>
      team.products?.includes(preset.productId)
    ) || []
  ).asc("name");
  const { register, setValue, getValues } = useFormContext();

  useEffect(() => {
    if (due) {
      setValue(`selectedPresets.${index}.due`, due);
    }
  }, []);

  useEffect(() => {
    const team = getValues(`selectedPresets.${index}.team`);
    if ((isNullish(team) || team == "Select") && availableTeams) {
      setValue(
        `selectedPresets.${index}.team`,
        availableTeams[0]?.id.toString()
      );
    }
  }, [availableTeams]);

  return (
    <div className="flex flex-col gap-2">
      <div className="font-semibold">
        {productSet?.name} - {preset.name}
      </div>
      <div className="flex flex-col gap-2 ml-2">
        <Select
          sizing="sm"
          addon="Team"
          {...register(`selectedPresets.${index}.team`)}
        >
          <option value={undefined}>Select</option>
          {availableTeams?.map(team => (
            <option key={team?.id} value={team?.id}>
              {team.name}
            </option>
          ))}
        </Select>
        <TextInput
          addon="Due"
          sizing="sm"
          type="date"
          {...register(`selectedPresets.${index}.due`)}
        />
        <Textarea
          placeholder="remarks"
          className="text-sm"
          {...register(`selectedPresets.${index}.desc`)}
        />
      </div>
    </div>
  );
};

import axios from "axios";
const API = import.meta.env.VITE_API;

interface props {
  recipients: string[];
  subject: string;
  text: string;
  html: string;
}

export default async function sendMail({
  recipients,
  subject,
  text,
  html,
}: props) {
  const res = await axios.post(API + "/mail/send", {
    recipients,
    subject,
    text,
    html,
  });
  console.log(res.data);
  return;
}

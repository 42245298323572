import { useEffect, useState } from "react";
import { OrderType } from "../../workorder/types";
import { Badge, Button, Label, Spinner } from "flowbite-react";
import dayjs from "dayjs";
import { useMutation, useSubscription } from "@apollo/client";
import { DELETE_ORDER, UPDATE_ORDER_BY_PK } from "../../workorder/gqls";
import { STREAM_PRODUCTIONS } from "../../production/gql";
import { useNavigate } from "react-router-dom";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { ClockIcon, TrashIcon } from "@heroicons/react/24/solid";
import getContrast from "../../../../utils/getContrast";
import { useAuthStore } from "../../../../store/authStore";
import { addAlert } from "../../../../store/alertStore";
import useGetOrderProducts from "../../workorder/useGetOrderProducts";

interface props {
  order: OrderType;
}

export default function Order({ order }: props) {
  const { data: production_data_all } = useSubscription(STREAM_PRODUCTIONS, {
    variables: {
      where: {
        processID: { _eq: order.process.id },
      },
      cursor: {
        initial_value: { updated_at: dayjs().format("YYYY-MM-DD") },
        ordering: "ASC",
      },
    },
    onError(error) {
      console.log(error);
    },
  });

  const myProductions = production_data_all?.productions_stream.filter(
    production => order.productionIds?.includes(production.id)
  );

  const getOrderProducts = useGetOrderProducts();

  const products = getOrderProducts(order.order.locations);

  // const pastDue = () => {
  //   if (order.status.name == "completed") {
  //     return false;
  //   }
  //   return dayjs().isAfter(dayjs(order.due));
  // };

  const [extended, setExtended] = useState(false);
  const toggleExtended = () => {
    setExtended(!extended);
  };

  const [remove, { loading: deleting }] = useMutation(DELETE_ORDER);

  const _delete = () => {
    const proceed = confirm("Do you really want to delete the document?");
    if (proceed) {
      remove({
        variables: { id: order.id },
        update(cache, { data: { delete_workorder_by_pk: deleted } }) {
          const normalizedId = cache.identify({
            id: deleted.id,
            __typename: "workorder",
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    }
  };

  const navigate = useNavigate();

  const { attributes, listeners, setNodeRef, transform, isDragging, over } =
    useDraggable({
      id: order.id,
      data: {
        order,
      },
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    opacity: isDragging && !over ? 0.5 : 1,
  };

  const [update] = useMutation(UPDATE_ORDER_BY_PK);

  const deleteProduction = (id: number) => {
    const proceed = confirm("withdraw production assignment?");
    if (proceed) {
      update({
        variables: {
          id: order.id,
          set: {
            productionIds: order.productionIds?.filter(
              production => production !== id
            ),
          },
        },
      });
    }
  };

  const { user } = useAuthStore();

  const [editing, setEditing] = useState(false);

  const checkEditing = () => {
    const minDiff = dayjs(order.updated_at).diff(dayjs(), "minute");
    setEditing(minDiff > -2 && order.lastUpdatedUser?.id !== user?.id);
  };

  useEffect(() => {
    const interval = setInterval(() => checkEditing(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const goToOrder = () => {
    if (editing) {
      addAlert({
        message: `${order.lastUpdatedUser?.firstName} ${order.lastUpdatedUser?.sirName} is editing the workorder`,
        type: "warning",
      });
      return;
    }
    navigate(`/order/detail/${order.id}`);
  };

  return (
    <div
      className={`shadow-md bg-white dark:bg-gray-800 rounded-md p-3 flex flex-col gap-2 ${
        !extended && "dark:hover:bg-gray-700 hover:bg-gray-50"
      }`}
      style={style}
    >
      <div
        className="flex flex-row gap-2 items-center justify-between cursor-pointer"
        onClick={toggleExtended}
        {...attributes}
        {...listeners}
        ref={setNodeRef}
      >
        <div className="flex flex-col gap-1">
          <h4 className="font-semibold">{order.orderRef || "No Order Ref"}</h4>
          <div className="flex flex-row gap-2 items-center">
            <Badge
              style={{
                backgroundColor: order.type.color,
                color: getContrast(order.type.color),
              }}
            >
              {order.type.name}
            </Badge>
            <div className="flex flex-row gap-1 items-center leading-[0]">
              <ClockIcon className="w-4" />
              {order.due ? dayjs(order.due).format("DD/MM/YYYY") : "Nill"}
            </div>
          </div>
        </div>

        <Badge
          style={{
            backgroundColor: order.status.color,
            color: getContrast(order.status.color),
          }}
        >
          {order.status.name}
        </Badge>
      </div>
      {extended && (
        <>
          {/* ID */}
          <div className="gap-2 flex flex-row justify-between">
            <Label value="Id" />
            {order.id}
          </div>
          {/* Products */}
          <div className="gap-2 flex flex-row justify-between">
            <Label value="Products" />
            <div className="flex flex-1 flex-row gap-2 items-center justify-end">
              {products?.map(product => (
                <div
                  key={product.name}
                  className="flex flex-row gap-1 items-center"
                >
                  <div className="ring-1 ring-dark rounded-full p-1 bg-white">
                    <img src={product.img} className="w-5" />
                  </div>
                  <span className="text-lg">{product.qty}</span>
                </div>
              ))}
              {(!products || products.length < 1) && "None"}
            </div>
          </div>
          {/* Production */}
          <div className="gap-2 flex flex-row justify-between">
            <Label value="Assigned" />
            <div className="flex flex-row flex-wrap items-center gap-2">
              {myProductions?.map(production => {
                return (
                  <Badge
                    className="cursor-pointer group"
                    key={production.id}
                    style={{
                      backgroundColor: production.productionStatus.color,
                    }}
                    onClick={() => {
                      deleteProduction(production.id);
                    }}
                  >
                    <div className="flex flex-row items-center gap-2">
                      <span>
                        {production.team.name}-
                        {production.productionStatus.name}
                      </span>
                      <TrashIcon className="hidden group-hover:block text-red-500 w-5" />
                    </div>
                  </Badge>
                );
              })}
              {(!myProductions || myProductions.length < 1) && "None"}
            </div>
          </div>

          {/* Released */}
          <div className="gap-2 flex flex-row justify-between">
            <Label value="Entered" />
            <div>
              {dayjs(order.created_at).format("DD/MM/YYYY")} by{" "}
              <span className="capitalize">
                {order.user.firstName} {order.user.sirName}
              </span>
            </div>
          </div>

          {/* Desc */}
          <Label value="Desc" />
          <div className="w-full">
            <textarea
              rows={3}
              value={order.description}
              disabled
              className={`resize-none text-sm bg-gray-100 dark:bg-gray-700 rounded-md border-none w-full scrollbar-none `}
            />
          </div>

          {/* Files */}

          {/* Action */}
          <div className="flex flex-row gap-2 justify-end items-center">
            <Button onClick={_delete} size="xs" color="failure">
              Delete
              {deleting && (
                <Spinner light size="xs" color="failure" className="ml-1" />
              )}
            </Button>
            <Button
              onClick={goToOrder}
              size="xs"
              outline
              gradientDuoTone="purpleToBlue"
            >
              View
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

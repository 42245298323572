import { TextInput, Tooltip } from "flowbite-react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import { ProductSetType } from "../../../../types";
import { useDebounce } from "usehooks-ts";
import { useEffect } from "react";
import { itemWarning } from "../../../types";
import { usePub } from "../../../../../../../utils/pubsub/pubsub";
import {
  orderEvent,
  SIZE_UPDATE_ARGS,
} from "../../../../../../../utils/pubsub/orderEventArgs";

interface props {
  field: ControllerRenderProps<FieldValues, `${string}.width`>;
  sizeLimits: ProductSetType["sizeLimit"];
  warnings?: itemWarning[];
  openingId: string;
  itemId: string;
  locked: boolean;
}
export default function Width({
  field,
  sizeLimits,
  warnings,
  openingId,
  itemId,
  locked,
}: props) {
  const { onChange, onBlur, value } = field;

  const publish = usePub<SIZE_UPDATE_ARGS>();

  const debouncedValue = useDebounce(value, 1000);

  useEffect(() => {
    if (locked) {
      return;
    }
    const value = Number(debouncedValue);
    if (sizeLimits?.width) {
      if (sizeLimits.width?.max && value > sizeLimits.width.max) {
        onChange(sizeLimits.width.max);
      }
      if (sizeLimits.width?.min && value < sizeLimits.width.min) {
        onChange(sizeLimits.width.min);
      }
    }

    publish(orderEvent.SIZE_UPDATE, {
      orderEvent: orderEvent.SIZE_UPDATE,
      openingId,
      itemId,
    });
  }, [debouncedValue]);

  const isWarningPresent = warnings && warnings.length > 0;

  const myWarnings =
    warnings
      ?.map(
        warning =>
          `${warning?.presetOption ? `${warning?.presetOption}: ` : ""}${
            warning?.value
          }`
      )
      .join("\n") || "";

  return (
    <Tooltip
      className={`${isWarningPresent ? "" : "hidden"}`}
      content={<pre>{myWarnings}</pre>}
    >
      <TextInput
        sizing="sm"
        type="number"
        placeholder="width"
        addon="W"
        className={`w-28 mr-1 h-fit self-end my-[5px] ${
          isWarningPresent && "ring-rose-300 ring-1 rounded-md"
        }`}
        onChange={onChange}
        disabled={locked}
        onBlur={onBlur}
        value={value}
        onKeyDown={e => {
          if (e.key == "ArrowUp" || e.key == "ArrowDown") e.preventDefault();
        }}
      />
    </Tooltip>
  );
}

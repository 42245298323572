import { useMutation, useQuery } from "@apollo/client";
import { Button } from "flowbite-react";
import {
  GET_PRODUCT_SETS,
  UPDATE_PRODUCT_SET_BY_PK,
} from "../../../workorder/gqls";
import { ProductSetType } from "../../../workorder/types";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "usehooks-ts";
import NewProductSet from "./newProductSet";
import {
  Active,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useMemo, useState } from "react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { sort } from "fast-sort";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { CSS } from "@dnd-kit/utilities";

export default function ProductSets() {
  const [sortedProductSets, setSortedProductSets] = useState<ProductSetType[]>(
    []
  );

  useQuery(GET_PRODUCT_SETS, {
    onCompleted(data) {
      if (data.productSets)
        setSortedProductSets(sort(data?.productSets).asc("position"));
    },
  });

  const { value: adding, setTrue, setFalse } = useBoolean();

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () => sortedProductSets.find(item => item.id === theActive?.id),
    [theActive, sortedProductSets]
  );

  const [touched, setTouched] = useState(false);

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = sortedProductSets.findIndex(
        ({ id }) => id === active.id
      );
      const overIndex = sortedProductSets.findIndex(({ id }) => id === over.id);
      const movedArray = arrayMove(sortedProductSets, activeIndex, overIndex);
      setSortedProductSets(movedArray);
      setTouched(true);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const resetSort = () => {
    setSortedProductSets(sort(sortedProductSets).asc("position"));
    setTouched(false);
  };

  const [update] = useMutation(UPDATE_PRODUCT_SET_BY_PK);

  const applySort = () => {
    const positionMappedProductSets = sortedProductSets.map(
      (productSet, i) => ({
        ...productSet,
        position: i,
      })
    );
    setSortedProductSets(positionMappedProductSets);
    positionMappedProductSets.map(productSet => {
      update({
        variables: {
          id: productSet.id,
          set: {
            position: productSet.position,
          },
        },
      });
    });
    setTouched(false);
  };

  const newPosition = sortedProductSets.length + 1;

  return (
    <div>
      <div className="select-text flex flex-col gap-1">
        <div className="flex flex-row rounded-t-md dark:bg-gray-600 bg-gray-100 py-2 font-semibold uppercase">
          <div className="text-xs px-2 w-10">#</div>
          <div className="text-xs px-2 w-40">Product</div>
          <div className="text-xs px-2 w-40">Name</div>
          <div className="text-xs px-2 w-28">Type</div>
          <div className="text-xs px-2 flex-1">Options</div>
        </div>
      </div>
      <div className="text-black dark:text-white">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToWindowEdges]}
        >
          <SortableContext
            items={sortedProductSets.map(opt => opt.id)}
            strategy={verticalListSortingStrategy}
          >
            {sortedProductSets.map((item, i) => (
              <Item item={item} key={item.id} index={i} />
            ))}
          </SortableContext>
        </DndContext>
      </div>
      <div className="flex flex-row justify-end mt-4 gap-2 items-center">
        {touched && (
          <>
            <Button size="xs" color="purple" onClick={resetSort}>
              reset sorting
            </Button>
            <Button
              size="xs"
              outline
              gradientDuoTone="greenToBlue"
              onClick={applySort}
            >
              apply sorting
            </Button>
          </>
        )}
        <Button
          size="xs"
          outline
          onClick={setTrue}
          gradientDuoTone="purpleToBlue"
        >
          Add New Product Set
        </Button>
      </div>
      {adding && (
        <NewProductSet newPosition={newPosition} setFalse={setFalse} />
      )}
    </div>
  );
}

interface props {
  item: ProductSetType;
  index: number;
}
const Item = ({ item, index }: props) => {
  const navigate = useNavigate();
  const edit = () => {
    navigate(`/settings/order/productSet/${item.id}`);
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      className={`flex flex-row cursor-pointer group ${
        index && index % 2 !== 0 && "dark:bg-gray-600 bg-gray-100"
      }`}
      ref={setNodeRef}
      style={style}
      onClick={edit}
    >
      <div
        {...attributes}
        {...listeners}
        className="w-10 py-[5px] px-2 border-r-[1px] dark:border-gray-500"
      >
        <ChevronUpDownIcon className="hidden group-hover:block w-5 " />
      </div>
      <div className="w-40 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
        {item.product.name}
      </div>
      <div className="w-40 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
        {item.name}
      </div>
      <div className="w-28 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500">
        {item.hideItems ? "Opening" : "Item"}
      </div>
      <div className="flex-1 w-0 truncate py-[5px] px-2 border-r-[1px] dark:border-gray-500 gap-2">
        {item.productSetOptions?.map(o => o.name).join(", ")}
      </div>
    </div>
  );
};

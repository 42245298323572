import { useSearchStore } from "../../../store/searchStore";
import { gql, useLazyQuery, TypedDocumentNode } from "@apollo/client";
import { PROCESS_CORE_FIELDS } from "../process/fragments";
import { ProcessType } from "../process/types";
import { GET_PROCESS_TYPES } from "../settings/process/processTypes/processTypes";
import { searchResult } from "../../../store/searchStore";
import { fullNumber } from "../../../utils/fullNumber";
import checkAuth from "../../../utils/checkAuth";
import { GET_ORDERS_CONDITIONAL } from "./gqls";
import { useAuthStore } from "../../../store/authStore";

interface searchResults {
  search_process: ProcessType[];
}

interface processQueryDocument {
  process: ProcessType[];
}

export const QUERY_PROCESS_BY_NUMBER: TypedDocumentNode<processQueryDocument> = gql`
  ${PROCESS_CORE_FIELDS}
  query QUERY_PROCESS($where: process_bool_exp) {
    process(where: $where, limit: 5) {
      ...ProcessCoreFields
    }
  }
`;

export const SEARCH_PROCESS: TypedDocumentNode<searchResults> = gql`
  ${PROCESS_CORE_FIELDS}
  query SEARCH_PROCESS($str: String!) {
    search_process(args: { search: $str }) {
      ...ProcessCoreFields
    }
  }
`;

export const useOrderSearch = () => {
  const { setSearchResults, setLoading } = useSearchStore();

  const [getProcessTypes] = useLazyQuery(GET_PROCESS_TYPES);

  const [searchProcessByNum] = useLazyQuery(QUERY_PROCESS_BY_NUMBER, {
    fetchPolicy: "cache-and-network",
  });

  const [searchProcess] = useLazyQuery(SEARCH_PROCESS, {
    fetchPolicy: "cache-and-network",
  });

  const [searchOrders] = useLazyQuery(GET_ORDERS_CONDITIONAL, {
    fetchPolicy: "cache-and-network",
  });

  const { user } = useAuthStore();

  const searchFn = async (str: string) => {
    const year = Number(str.slice(0, 2));
    const number = Number(str.replace(year.toString(), ""));
    const notNum = isNaN(year) || isNaN(number);

    try {
      if (str && str.trim() !== "") {
        const isFullNumberRegex = /[A-Za-z]+[0-9]+/i;
        const isNumberRegex = /[0-9]+/i;

        if (isFullNumberRegex.test(str)) {
          const numberPart = str.replace(/^\D+/g, "");
          const prefix = str.replace(numberPart, "");
          const year = Number(numberPart.slice(0, 2));
          const number = Number(numberPart.replace(year.toString(), ""));

          const fullYear = Number(`20${year}`);

          let typeId = 0;

          const processTypeData = await getProcessTypes();
          const processTypes = processTypeData.data?.processType;

          if (processTypes) {
            typeId =
              processTypes.find(
                pt => pt.prefix?.toLowerCase() == prefix?.toLowerCase()
              )?.id || 0;
          }

          setLoading(true);

          searchProcessByNum({
            variables: {
              where: {
                _and: [
                  { year: { _eq: fullYear } },
                  { number: { _eq: number } },
                  { typeId: { _eq: typeId } },
                ],
              },
            },
            onCompleted(data) {
              const results: searchResult[] = data.process
                .filter(p => p.workorders && p.workorders.length > 0)
                .filter(
                  p =>
                    checkAuth([
                      "order_view_all",
                      {
                        permission: "order_view_team",
                        checkGroup: "userTeam",
                        conditionGroup: [p.createdUser.team?.id],
                      },
                    ]) || p.createdUser.id == user?.id
                )
                .map(
                  p =>
                    p.workorders?.map(wo => ({
                      ...wo,
                      fullNum: fullNumber(
                        p.processType.prefix,
                        p.year,
                        p.number,
                        p.salesRepUser
                      ),
                      name: `${p.name}${
                        wo.orderRef ? ` - ${wo.orderRef}` : ""
                      }`,
                      pId: p.id,
                    })) || []
                )
                .flat()
                .map(wo => ({
                  header: `${wo.fullNum} - ${wo.type?.name}`,
                  desc: wo.name,
                  link: `order/detail/${wo.id}`,
                }));

              searchOrders({
                variables: {
                  where: {
                    orderRef: {
                      _ilike: `%${str}%`,
                    },
                  },
                },
                onCompleted(data) {
                  const orderSearchRes: searchResult[] = data.workorder
                    .filter(
                      wo =>
                        checkAuth([
                          "order_view_all",
                          {
                            permission: "order_view_team",
                            checkGroup: "userTeam",
                            conditionGroup: [wo.process.createdUser.team?.id],
                          },
                        ]) || wo.createdBy == user?.id
                    )
                    .map(wo => ({
                      header: wo.orderRef || "",
                      desc: fullNumber(
                        wo.process.processType.prefix,
                        wo.process.year,
                        wo.process.number,
                        wo.process.salesRepUser
                      ),
                      link: `order/detail/${wo.id}`,
                    }));

                  setSearchResults(results.concat(orderSearchRes));
                  setLoading(false);
                },
                onError(error) {
                  console.log(error, "search by order Ref");
                  setLoading(false);
                  setSearchResults([]);
                },
              });
            },
            onError(error) {
              console.log(error, "search by fullnum");
              setLoading(false);
              setSearchResults([]);
            },
          });
          return;
        } else if (isNumberRegex.test(str) && !notNum) {
          const year = Number(str.slice(0, 2));
          const number = Number(str.replace(year.toString(), ""));

          const fullYear = Number(`20${year}`);

          setLoading(true);
          searchProcessByNum({
            variables: {
              where: {
                _and: [
                  { year: { _eq: fullYear } },
                  { number: { _eq: number } },
                ],
              },
            },
            onCompleted(data) {
              const results: searchResult[] = data.process
                .filter(p => p.workorders && p.workorders.length > 0)
                .filter(
                  p =>
                    checkAuth([
                      "order_view_all",
                      {
                        permission: "order_view_team",
                        checkGroup: "userTeam",
                        conditionGroup: [p.createdUser.team?.id],
                      },
                    ]) || p.createdUser.id == user?.id
                )
                .map(
                  p =>
                    p.workorders?.map(wo => ({
                      ...wo,
                      fullNum: fullNumber(
                        p.processType.prefix,
                        p.year,
                        p.number,
                        p.salesRepUser
                      ),
                      name: `${p.name}${
                        wo.orderRef ? ` - ${wo.orderRef}` : ""
                      }`,
                      pId: p.id,
                    })) || []
                )
                .flat()
                .map(wo => ({
                  header: `${wo.fullNum} - ${wo.type?.name}`,
                  desc: wo.name,
                  link: `order/detail/${wo.id}`,
                }));
              setSearchResults(results);
              setLoading(false);
            },
            onError(error) {
              console.log(error, "search by process Num");
              setLoading(false);
              setSearchResults([]);
            },
          });
          return;
        } else {
          setLoading(true);
          searchProcess({
            variables: { str },
            onCompleted(data) {
              const results: searchResult[] = data.search_process
                .filter(p => p.workorders && p.workorders.length > 0)
                .filter(
                  p =>
                    checkAuth([
                      "order_view_all",
                      {
                        permission: "order_view_team",
                        checkGroup: "userTeam",
                        conditionGroup: [p.createdUser.team?.id],
                      },
                    ]) || p.createdUser.id == user?.id
                )
                .map(
                  p =>
                    p.workorders?.map(wo => ({
                      ...wo,
                      fullNum: fullNumber(
                        p.processType.prefix,
                        p.year,
                        p.number,
                        p.salesRepUser
                      ),
                      name: `${p.name}${
                        wo.orderRef ? ` - ${wo.orderRef}` : ""
                      }`,
                      pId: p.id,
                    })) || []
                )
                .flat()
                .map(wo => ({
                  header: `${wo.fullNum} - ${wo.type?.name}`,
                  desc: wo.name,
                  link: `order/detail/${wo.id}`,
                }));

              searchOrders({
                variables: {
                  where: {
                    orderRef: {
                      _ilike: `%${str}%`,
                    },
                  },
                },
                onCompleted(data) {
                  const orderSearchRes: searchResult[] = data.workorder
                    .filter(
                      wo =>
                        checkAuth([
                          "order_view_all",
                          {
                            permission: "order_view_team",
                            checkGroup: "userTeam",
                            conditionGroup: [wo.process.createdUser.team?.id],
                          },
                        ]) || wo.createdBy == user?.id
                    )
                    .map(wo => ({
                      header: `${fullNumber(
                        wo.process.processType.prefix,
                        wo.process.year,
                        wo.process.number,
                        wo.process.salesRepUser
                      )} - ${wo.type.name}`,
                      desc: wo.orderRef || "",
                      link: `order/detail/${wo.id}`,
                    }));

                  setSearchResults(results.concat(orderSearchRes));
                  setLoading(false);
                },
                onError(error) {
                  console.log(error, "search by order Ref");
                  setLoading(false);
                  setSearchResults([]);
                },
              });
            },
            onError(error) {
              console.log(error, "search by process name");
              setLoading(false);
              setSearchResults([]);
            },
          });
        }
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      setSearchResults([]);
      setLoading(false);
      console.log(error);
    }
  };

  return searchFn;
};

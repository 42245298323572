import { Button, Modal, Tooltip } from "flowbite-react";
import { breakdownData } from "../../summary/getPrice";
import { useScheme } from "../../../../../../../store/schemeStore";
import { currencyFormat } from "../../../../../../../utils/numberMethods";
import isNullish from "../../../../../../../utils/isNullish";
import { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";

export default function BreakDown({
  open,
  setOpen,
  breakDownData,
  currentPrice,
  isRetail,
  customInstallPrice,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  breakDownData?: breakdownData;
  currentPrice: number;
  isRetail: boolean;
  customInstallPrice?: number;
}) {
  const { scheme } = useScheme();

  const [expandScopes, setExpandScopes] = useState(false);

  if (!breakDownData) return null;

  const {
    formula,
    materialCostBreakDowns,
    scopes,
    productionBreakDowns,
    retailMargin,
    subTotal,
    installBreakDown,
    wholesaleDiscountRate,
    retailDiscount,
    tablePriceBreakDown,
  } = breakDownData;

  return (
    <Modal
      show={open}
      onClose={() => {
        setOpen(false);
      }}
      className={`${scheme} !h-full !items-start`}
    >
      <Modal.Header>Price Breakdown</Modal.Header>
      <Modal.Body>
        <section className="dark:text-white flex flex-col gap-2">
          <Tooltip content="Formula = quote pricing formula">
            <h2>Pricing Formula</h2>
          </Tooltip>
          <pre className="p-2 border-[1px] dark:border-gray-300 dark:bg-gray-500 bg-gray-50 whitespace-pre-wrap">
            {formula || "NONE"}
          </pre>
          {/* Scopes */}
          <div className="flex flex-row justify-between items-center">
            <h1>Scopes</h1>
            <div
              className="hover:text-indigo-500 cursor-pointer"
              onClick={() => setExpandScopes(!expandScopes)}
            >
              {expandScopes ? (
                <EyeIcon className="w-5" />
              ) : (
                <EyeSlashIcon className="w-5" />
              )}
            </div>
          </div>
          {/* SQM */}
          {formula?.includes("sqm") ? (
            <>
              <div className="flex flex-row justify-between gap-2 items-center">
                <h2>SQM :</h2>
                <h2>{scopes.sqm}</h2>
              </div>
            </>
          ) : null}
          {/* SQM PRICE */}
          {formula?.includes("sqmPrice") ? (
            <>
              <div className="flex flex-row justify-between gap-2 items-center">
                <h2>SQM Price:</h2>
                <h2>{scopes.sqmPrice}</h2>
              </div>
            </>
          ) : null}
          {/* Table Price */}
          {formula?.includes("tablePrice") && tablePriceBreakDown?.selected && (
            <table className="p-2 border-[1px] dark:border-gray-300 dark:bg-gray-500 bg-gray-50">
              <thead>
                <tr>
                  <th className="p-2 border-[1px] dark:border-gray-300">
                    Selected
                  </th>
                  <th className="p-2 border-[1px] dark:border-gray-300">
                    Formula
                  </th>
                  <th className="p-2 border-[1px] dark:border-gray-300">
                    Price
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2 border-[1px] dark:border-gray-300">
                    {tablePriceBreakDown.selected}
                  </td>
                  <td className="p-2 border-[1px] dark:border-gray-300">
                    {tablePriceBreakDown.formula}
                  </td>
                  <td className="p-2 border-[1px] dark:border-gray-300">
                    {tablePriceBreakDown.price}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {expandScopes && (
            <table className="p-2 border-[1px] dark:border-gray-300 dark:bg-gray-500 bg-gray-50">
              <thead>
                <tr>
                  <th className="p-2 border-[1px] dark:border-gray-300">
                    Name
                  </th>
                  <th className="p-2 border-[1px] dark:border-gray-300">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(scopes).map((key, i) => (
                  <tr key={i}>
                    <td className="p-2 border-[1px] dark:border-gray-300">
                      {key}
                    </td>
                    <td className="p-2 border-[1px] dark:border-gray-300">
                      {JSON.stringify(scopes[key])}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {/* Material */}
          {formula?.includes("material") ? (
            <>
              <h2>Material Breakdown</h2>
              <table className="p-2 border-[1px] dark:border-gray-300 dark:bg-gray-500 bg-gray-50">
                <thead>
                  <tr>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Material
                    </th>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Qty
                    </th>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Price ($)
                    </th>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Total ($)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {materialCostBreakDowns
                    .filter(
                      item =>
                        item.qty > 0 &&
                        item.material.name.toLowerCase() !== "none"
                    )
                    .map((item, i) => (
                      <tr key={i}>
                        <td className="p-2 border-[1px] dark:border-gray-300">
                          {item.material.brand} {item.material.name}{" "}
                          {item.material.color}
                        </td>
                        <td className="p-2 border-[1px] dark:border-gray-300">
                          {Math.round(item.qty * 100) / 100}
                        </td>
                        <td className="p-2 border-[1px] dark:border-gray-300">
                          {Math.round(item.price * 100) / 100}
                        </td>
                        <td className="p-2 border-[1px] dark:border-gray-300">
                          {item.noPrice
                            ? "Not Included"
                            : Math.round(item.total * 100) / 100}
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan={3}
                      className="p-2 border-[1px] dark:border-gray-300"
                    >
                      Total ($)
                    </td>
                    <td className="p-2 border-[1px] dark:border-gray-300">
                      {Math.round(
                        materialCostBreakDowns.reduce(
                          (total, item) => total + item.total,
                          0
                        ) * 100
                      ) / 100}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </>
          ) : null}
          {/* Production */}
          {formula?.includes("production") ? (
            <>
              <h2>Production Breakdown</h2>
              <table className="p-2 border-[1px] dark:border-gray-300 dark:bg-gray-500 bg-gray-50">
                <thead>
                  <tr>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Preset
                    </th>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Name
                    </th>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Labour f(x)
                    </th>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Total ($)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {productionBreakDowns.map((item, i) => (
                    <tr key={i}>
                      <td className="p-2 border-[1px] dark:border-gray-300">
                        {item.preset}
                      </td>
                      <td className="p-2 border-[1px] dark:border-gray-300">
                        {item.name}
                      </td>
                      <td className="p-2 border-[1px] dark:border-gray-300">
                        {item.labour}
                      </td>
                      <td className="p-2 border-[1px] dark:border-gray-300">
                        {Math.round(item.total * 100) / 100}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan={3}
                      className="p-2 border-[1px] dark:border-gray-300"
                    >
                      Total ($)
                    </td>
                    <td className="p-2 border-[1px] dark:border-gray-300">
                      {Math.round(
                        productionBreakDowns.reduce(
                          (total, item) => total + item.total,
                          0
                        ) * 100
                      ) / 100}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </>
          ) : null}
          {/* Wholesale Margin */}
          {formula?.includes("margin") ? (
            <>
              <div className="flex flex-row justify-between gap-2 items-center">
                <Tooltip content="margin from Pricing Formula">
                  <h2>Wholesale Margin :</h2>
                </Tooltip>
                <h2>{scopes.margin} %</h2>
              </div>
            </>
          ) : null}
          {/* Sub Total */}
          <h1 className="text-right">{currencyFormat(subTotal)}</h1>
          <hr />
          {/* Retail Margin */}
          {isRetail && retailMargin ? (
            <>
              <div className="flex flex-row justify-between gap-2 items-center">
                <h2>Retail Margin :</h2>
                <h2>{retailMargin} %</h2>
              </div>
              <h1 className="text-right">
                {currencyFormat(subTotal / ((100 - retailMargin) / 100))}
              </h1>
              <hr />
            </>
          ) : null}
          {/* Wholeslae Discount */}
          {!isRetail && wholesaleDiscountRate ? (
            <>
              <div className="flex flex-row justify-between gap-2 items-center">
                <h2>Wholesale Discount Rate :</h2>
                <h2>{wholesaleDiscountRate} %</h2>
              </div>
            </>
          ) : null}
          {/* Retail Discount */}
          {(isRetail && !isNullish(retailDiscount?.perItemDiscount, true)) ||
          !isNullish(retailDiscount?.value, true) ? (
            <>
              <div className="flex flex-row justify-between gap-2 items-center">
                <h2>Retail Discount</h2>
                {!isNullish(retailDiscount?.perItemDiscount, true) && (
                  <h1 className="!text-red-500">
                    {currencyFormat(retailDiscount?.perItemDiscount!!)}
                  </h1>
                )}
                {!isNullish(retailDiscount?.value, true) && (
                  <h1 className="!text-red-500">
                    {retailDiscount?.type === "rate"
                      ? `${retailDiscount?.value}%`
                      : currencyFormat(retailDiscount?.value!!)}
                  </h1>
                )}
              </div>
              <h1 className="text-right !text-red-500">
                {currencyFormat(retailDiscount?.deductedValue || 0)}
              </h1>
            </>
          ) : null}
          {/* Installation */}
          {isRetail && installBreakDown ? (
            <>
              <h2>Installation</h2>
              <table className="p-2 border-[1px] dark:border-gray-300 dark:bg-gray-500 bg-gray-50">
                <thead>
                  <tr>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Formula
                    </th>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Type
                    </th>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Qty
                    </th>
                    <th className="p-2 border-[1px] dark:border-gray-300">
                      Base Cost ($)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-2 border-[1px] dark:border-gray-300">
                      {installBreakDown.formula}
                    </td>
                    <td className="p-2 border-[1px] dark:border-gray-300">
                      {installBreakDown.formulaType}
                    </td>
                    <td className="p-2 border-[1px] dark:border-gray-300">
                      {installBreakDown.qty}
                    </td>
                    <td className="p-2 border-[1px] dark:border-gray-300">
                      {currencyFormat(installBreakDown.baseCost)}
                    </td>
                  </tr>
                </tbody>
              </table>
              {installBreakDown.additionalCharges.length > 0 && (
                <>
                  <h3>Additional Charges</h3>
                  <table className="p-2 border-[1px] dark:border-gray-300 dark:bg-gray-500 bg-gray-50">
                    <thead>
                      <tr>
                        <th className="p-2 border-[1px] dark:border-gray-300">
                          Name
                        </th>
                        <th className="p-2 border-[1px] dark:border-gray-300">
                          Formula
                        </th>
                        <th className="p-2 border-[1px] dark:border-gray-300">
                          Cost ($)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {installBreakDown.additionalCharges.map((item, i) => (
                        <tr key={i}>
                          <td className="p-2 border-[1px] dark:border-gray-300">
                            {item.name}
                          </td>
                          <td className="p-2 border-[1px] dark:border-gray-300">
                            {item.formula}
                          </td>
                          <td className="p-2 border-[1px] dark:border-gray-300">
                            {currencyFormat(item.price)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
              <div className="flex flex-row justify-between gap-2 items-center">
                <h2>Install Total :</h2>
                <h1>
                  {!isNullish(customInstallPrice) ? (
                    <>
                      {currencyFormat(customInstallPrice || 0)}{" "}
                      <span className="line-through opacity-50">
                        {installBreakDown.total}
                      </span>
                    </>
                  ) : (
                    currencyFormat(installBreakDown.total)
                  )}{" "}
                </h1>
              </div>
              <hr />
            </>
          ) : null}
          <h1 className="flex flex-row justify-end">
            Total: {currencyFormat(currentPrice)}
          </h1>
        </section>
      </Modal.Body>
      <Modal.Footer className="justify-end gap-2">
        <Button size="sm" color="purple" onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { useQuery } from "@apollo/client";
import { useAuthStore } from "../../../../store/authStore";
import { fullNumber } from "../../../../utils/fullNumber";
import { push } from "../../../../utils/pushAPI";
import { GET_ORDER_STATUS } from "../gqls";
import { OrderType } from "../types";
import { GET_USERS_CONDITIONAL } from "../../settings/users/gql";
import sendMail from "../../../../utils/sendMail";
const wholesaleEmail = import.meta.env.VITE_WHOLESALE_EMAIL;

export default function usePushOrderStatus() {
  const { user } = useAuthStore();
  const { data: data_statuses } = useQuery(GET_ORDER_STATUS);
  const statuses = data_statuses?.workorderStatus;

  const adminId = 1;
  const office_adminId = 21;
  const office_leaderId = 24;

  const dealerTeamId = 20;

  const { data: data_users } = useQuery(GET_USERS_CONDITIONAL, {
    variables: {
      where: {
        _and: [
          { authID: { _in: [adminId, office_adminId, office_leaderId] } },
          { team: { subTeams: { _contains: dealerTeamId } } },
          { active: { _eq: true } },
        ],
      },
    },
  });

  const userIdsToNotifyWhenConfirmed = data_users?.users?.map(u => u.id);

  return (order: OrderType, statId: number) => {
    const process = order?.process;

    const fullNum = process
      ? fullNumber(
          process.processType.prefix,
          process.year,
          process.number,
          process.salesRepUser
        )
      : "";

    const statName = statuses?.find(s => s.id == statId)?.name;
    const title = `${fullNum} Order Status Changed to ${statName} - OrderID:${order.id}`;

    const body = `An Order Status has been updated`;

    const isConfirmed = statId == 2;
    const isDealerOrder = order?.type.id == 4;

    let ids = [order.createdBy];

    if (isConfirmed) {
      // SEND MAIL TO EMAIL
      if (isDealerOrder) {
        // console.log("sent");
        sendMail({
          recipients: [wholesaleEmail],
          subject: title,
          html: body,
          text: body,
        });
      }

      if (userIdsToNotifyWhenConfirmed) {
        ids.push(...userIdsToNotifyWhenConfirmed);
      }
    }

    ids = ids.filter(id => id !== user?.id);

    if (ids.length < 1) {
      return;
    }

    push({
      title,
      body,
      ids,
    });
  };
}

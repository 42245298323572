import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";
import { GET_MATERIALS_CONDITIONAL } from "../../materials/gqls";
import {
  GET_DEDUCTIONS_CONDITIONAL_NO_IMG,
  GET_DEDUCTION_PRESETS_CONDITIONAL,
  GET_PRODUCT_OPTIONS_BY_ID,
} from "../../workorder/gqls";

interface props {
  option: number;
  value?: any;
}

const excludedOptionTypes = ["text", "number"];

const usePopulateOption = () => {
  const client = useApolloClient();

  const populate = async ({ option, value }: props) => {
    if (!option) {
      return {
        option: "Not Found",
        value,
      };
    }
    const { data } = await client.query({
      query: GET_PRODUCT_OPTIONS_BY_ID,
      variables: {
        id: option,
      },
    });

    const populatedOption = data?.productSetOptions_by_pk;

    // console.log(populatedOption);

    const optionName = populatedOption?.name;
    const source = populatedOption?.source;

    if (excludedOptionTypes.includes(populatedOption?.type)) {
      return { option: optionName, value };
    }
    const defaultReturnValue = {
      option: optionName,
      value,
    };

    if (!source) {
      return {
        option: optionName,
        value,
      };
    }

    if (source == "deductionPreset") {
      const { data } = await client.query({
        query: GET_DEDUCTION_PRESETS_CONDITIONAL,
        variables: {
          where: { id: { _eq: Number(value) } },
        },
      });

      if (data?.deductionPreset && data.deductionPreset[0]) {
        const populatedVal = data.deductionPreset[0]?.name;
        return {
          option: optionName,
          value: populatedVal,
        };
      } else {
        return defaultReturnValue;
      }
    }
    if (source == "deductions") {
      const { data } = await client.query({
        query: GET_DEDUCTIONS_CONDITIONAL_NO_IMG,
        variables: {
          where: {
            id: { _eq: Number(value) },
          },
        },
      });

      if (data?.deductions && data.deductions[0]) {
        const populatedVal = data.deductions[0]?.name;

        return {
          option: optionName,
          value: populatedVal,
        };
      } else {
        return defaultReturnValue;
      }
    }
    if (source == "inventory") {
      if (isNaN(Number(value))) {
        return defaultReturnValue;
      }
      const { data } = await client.query({
        query: GET_MATERIALS_CONDITIONAL,
        variables: {
          where: {
            id: { _eq: Number(value) },
          },
        },
      });

      if (data?.materials && data.materials[0]) {
        const populatedVal = `${data.materials[0]?.name} ${data.materials[0].color}`;

        return {
          option: optionName,
          value: populatedVal,
        };
      } else {
        return defaultReturnValue;
      }
    }
  };

  return useCallback(populate, [client]);
};

export default usePopulateOption;

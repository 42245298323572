import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { OrderType } from "../../../types";
import useRefineOrder from "../../../useRefineOrder";
import { useFormContext } from "react-hook-form";
import {
  additionalMat,
  inventory,
  location,
  order,
  salesParams,
} from "../../types";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_ORDER_BY_PK } from "../../../gqls";
import { Button, Spinner } from "flowbite-react";
import { UPDATE_PROCESS_BY_PK } from "../../../../process/processDetail/gqls";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { fullNumber } from "../../../../../../utils/fullNumber";
import { useOrderStore } from "../../../../../../store/orderStore";
import { sort } from "fast-sort";
import SummaryItem from "./summaryItem";
import isNullish from "../../../../../../utils/isNullish";
import { useReactToPrint } from "react-to-print";
import { currencyFormat } from "../../../../../../utils/numberMethods";
import CustomSummaryItem from "./customSummaryItem";
import { useQuoteStore } from "../../../../../../store/quoteStore";
import logo from "../../../../../../assets/eziluxLogo.png";
import jncLogo from "/jnc.jpg";
import { GET_CONTACTS_BY_IDS } from "../../../../process/processDetail/processActions";
import "./summaryPage.css";
import useGetOrderProducts from "../../../useGetOrderProducts";
import useGetBlindFabricCutCharge from "../useGetBlindFabricCutCharge";
import { addAlert } from "../../../../../../store/alertStore";
import qr from "/tncqr.png";

interface props {
  dbOrder?: OrderType;
  canConfirm?: boolean;
  cancelFunc?: () => void;
  noPrice?: boolean;
}

export default function SummaryPage({
  dbOrder,
  canConfirm,
  cancelFunc,
  noPrice,
}: props) {
  const refineOrder = useRefineOrder();
  const methods = useFormContext<order>();

  const { orderSummaryColumn, productSets, materials, units } = useOrderStore();
  const { quoteParams } = useQuoteStore();
  const [locations, setLocations] = useState<location[]>([]);
  const [remarks, setRemarks] = useState("");
  const singleLocation = locations.length < 2;
  const [additionalMats, setAdditionalMats] = useState<additionalMat[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (methods?.getValues) {
      const orderData = methods.getValues();
      setRemarks(orderData.description || "");
      refineOrder(orderData.locations).then(refinedLocs => {
        setLocations(refinedLocs);
        setLoading(false);
      });
      setAdditionalMats(orderData.additionalMats || []);
    } else if (dbOrder?.order) {
      setRemarks(dbOrder.order?.description || "");
      refineOrder(dbOrder?.order.locations).then(refinedLocs => {
        setLocations(refinedLocs);
        setLoading(false);
      });
      setAdditionalMats(dbOrder?.order.additionalMats || []);
    }
  }, []);

  const openings = locations
    .map((l, i) => l.openings.map(o => ({ ...o, li: i + 1, lName: l.name })))
    .flat();

  let exposedMats: inventory[] = [];

  openings
    .map(o => o.items || [])
    .flat()
    .forEach(i => {
      if (i.materials) {
        i.materials.forEach(mat => {
          if (mat.expose) {
            if (exposedMats.find(em => em.id == mat.id)) {
              exposedMats = exposedMats.map(em => {
                if (em.id == mat.id) {
                  return {
                    ...em,
                    qty: em.qty + mat.qty,
                  };
                }
                return em;
              });
            } else {
              exposedMats.push({
                ...mat,
                qty: mat.qty,
              });
            }
          }
        });
      }
    });

  const exposedMatsPopulated = exposedMats.map(em => {
    const mat = materials.find(m => m.id == em.id);
    return {
      ...em,
      name: `${mat?.brand ? `${mat.brand} ` : ""}${mat?.name} ${mat?.color}${
        mat?.size ? ` ${mat.size}` : ""
      }`,
      unit: units.find(u => u.id == em.unit)?.name,
    };
  });

  const matTotalPrice = additionalMats?.reduce<number>((prev, cur) => {
    const mat = materials.find(m => m.id == cur.id);
    const price =
      (mat?.prices.find(p => p.unit == cur.unit)?.price || 0) * cur.qty;
    return prev + price;
  }, 0);

  const salesParams: salesParams | undefined =
    methods?.getValues("salesParams");

  const discount = {
    value: Number(salesParams?.totalDiscount?.value || 0),
    type: salesParams?.totalDiscount?.type || "rate",
  };

  const gstInclusive = salesParams?.gstInclusive;

  const orderType = Number(dbOrder?.type.id || methods?.getValues("type")?.id);
  const isRetail = orderType !== 4;
  const separateInstall = isRetail;

  const isMeasureIncluded =
    isRetail &&
    (isNullish(salesParams?.measureInclude)
      ? true
      : salesParams?.measureInclude);

  const diffMinCharge = () => {
    if (!isRetail) {
      return 0;
    }

    const defaultMinCharge = quoteParams.find(
      p => p.name == "minInstallCharge"
    )?.value;

    const minCharge = isNullish(salesParams?.minInstallCharge)
      ? Number(defaultMinCharge)
      : Number(salesParams?.minInstallCharge);

    const installSum = openings.reduce((acc, o) => {
      let adder = 0;

      if (o.items) {
        const itemInstallCharge = o.items.reduce((acci, i) => {
          return (
            acci +
            Number(
              isNullish(i.customInstall) ? i.install || 0 : i.customInstall
            )
          );
        }, 0);

        adder += itemInstallCharge;
      }

      adder += Number(
        isNullish(o.customInstall) ? o.install || 0 : o.customInstall
      );
      return acc + adder;
    }, 0);

    return installSum < minCharge ? minCharge - installSum : 0;
  };

  const defaultMeasureCharge = quoteParams.find(
    p => p.name == "measureCharge"
  )?.value;

  const measureCharge = isNullish(salesParams?.measureCharge)
    ? defaultMeasureCharge
    : salesParams?.measureCharge;

  const getBlindCutFeeSurcharge = useGetBlindFabricCutCharge();

  const blindCutFeeSurcharge = getBlindCutFeeSurcharge(
    locations,
    additionalMats
  );

  let subTotal =
    openings.reduce<number>((acc, opening) => {
      let openingTotal = 0;

      const productSetId = Number(opening.product);
      const productSet = productSets.find(ps => ps.id == productSetId);
      const isCustom = opening.product == "custom";

      if (isCustom) {
        openingTotal +=
          Math.ceil(opening.price || 0) +
          Math.ceil(
            isNullish(opening?.customInstall)
              ? opening?.install || 0
              : opening.customInstall!!
          );
        return acc + openingTotal;
      }
      if (!productSet) {
        return acc;
      }
      if (productSet.hideItems) {
        openingTotal +=
          Math.ceil(opening.price || 0) +
          Math.ceil(
            isNullish(opening?.customInstall)
              ? opening?.install || 0
              : opening.customInstall!!
          );
        return acc + openingTotal;
      }

      for (const item of opening.items) {
        openingTotal +=
          Math.ceil(item.price || 0) +
          Math.ceil(
            isNullish(item?.customInstall)
              ? item?.install || 0
              : item.customInstall!!
          );
      }

      return acc + Number(openingTotal);
    }, 0) +
    Number(matTotalPrice || 0) +
    Number(isMeasureIncluded ? measureCharge : 0) +
    diffMinCharge() +
    blindCutFeeSurcharge;

  const hasItemDiscounts = openings.some(o => {
    return (
      !isNullish(o.salesParams?.discount, true) ||
      o.items?.some(i => {
        return !isNullish(i.salesParams?.discount, true);
      })
    );
  });

  const hasProductDiscounts = salesParams?.productParams?.some(
    param => !isNullish(param?.discount?.value, true)
  );

  const hasDiscounts = hasItemDiscounts || hasProductDiscounts;

  let incDC = 0;

  if (hasProductDiscounts) {
    for (const opening of openings) {
      const productSetId = Number(opening.product);
      if (!productSetId) {
        continue;
      }
      const myDiscountParam = salesParams?.productParams?.find(
        p => p.productSetId == productSetId
      );

      if (isNullish(myDiscountParam?.discount?.value, true)) {
        continue;
      }

      const dcValue = Number(myDiscountParam?.discount?.value);
      const isRate = myDiscountParam?.discount?.type == "rate";

      if (opening.price) {
        const discountedDollars = isRate
          ? opening.price / (1 - dcValue / 100) - opening.price
          : dcValue;

        incDC += discountedDollars;
      }

      if (opening.items && Array.isArray(opening.items)) {
        for (const item of opening.items) {
          if (item.price) {
            const discountedDollars = isRate
              ? item.price / (1 - dcValue / 100) - item.price
              : dcValue;

            incDC += discountedDollars;
          }
        }
      }
    }
  }

  subTotal = subTotal + incDC;

  const finalDC =
    discount.type == "rate"
      ? (subTotal * discount.value) / 100
      : discount.value;

  const totalDC = finalDC + incDC;

  const afterDiscount = subTotal - totalDC;

  const gst = Number((afterDiscount * 0.1).toFixed(2));

  const total = afterDiscount + gst;

  const notes = openings.reduce<{ index: string; note?: string }[]>(
    (prev, opening) => {
      let myNotes = prev;
      const openingNote = opening.note;
      const index = `${locations.length > 1 ? opening.li + "." : ""}${
        opening.index
      }`;
      if (openingNote) {
        myNotes.push({
          index,
          note: openingNote,
        });
      }
      const itemNotes = opening.items.map(item => ({
        index: `${index}.${item.index}`,
        note: item.note,
      }));
      myNotes.push(...itemNotes.filter(note => !isNullish(note.note)));

      return myNotes;
    },
    []
  );

  const [update_order] = useMutation(UPDATE_ORDER_BY_PK);
  const [update_process] = useMutation(UPDATE_PROCESS_BY_PK);

  const navigate = useNavigate();

  const submitOrder = () => {
    if (!dbOrder) {
      return;
    }
    const proceed = confirm("confirm and submit order?");
    if (proceed) {
      update_order({
        variables: {
          id: dbOrder.id,
          set: {
            statusId: 2,
          },
        },
        onCompleted() {
          addAlert({
            message: `Thank you for your order.
Your order confirmation will be emailed to you soon. Your order is important to us. If you have not received your order confirmation email, please do not hesitate to email or call us.
            `,
            type: "success",
            timeout: 5000,
          });
          navigate("/order");
        },
      });
      if (dbOrder.process && dbOrder.process.processType.id == 4) {
        update_process({
          variables: {
            id: dbOrder.process.id,
            set: {
              status: 8,
            },
          },
        });
      }
    }
  };

  const process = dbOrder?.process;

  const primaryContact = process?.primaryContact;
  const processContacts = process?.contacts;

  const { data: contacts_data } = useQuery(GET_CONTACTS_BY_IDS, {
    variables: {
      ids: processContacts,
    },
  });

  const contacts = contacts_data?.contacts || [];

  const contact =
    contacts.find(c => c.id == primaryContact) || contacts[0] || undefined;

  const customer = {
    name: process?.name,
    contact,
    address: process?.address,
  };

  const fullNum = dbOrder?.process
    ? fullNumber(
        dbOrder.process.processType.prefix,
        dbOrder.process.year,
        dbOrder.process.number,
        dbOrder.process.salesRepUser
      )
    : "N/A";

  const getPageMargins = () => {
    return `
    @page { 
      margin: 0mm 0mm 0mm 0mm !important; 
    }
    @media all {
      .page-break {
        display: none;
      }
    }
    
    @media print {
      html, body {
        height: initial !important;
        overflow: hidden !important;
        -webkit-print-color-adjust: exact;
      }
      #filler {
        overflow: hidden !important
      }
    }
    
    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: always;
      }
      .no-print, .no-print * {
        display:none !important
      }
    }
    
    @page {
      size: 297mm 210mm;
    }
    `;
  };

  const isQuote = dbOrder?.type.name == "quote";

  const title = () => {
    if (isQuote) {
      return "Proposal";
    }

    if (dbOrder?.type.name == "order") {
      return "Proforma invoice";
    }

    return `${dbOrder?.type.name}`;
  };

  const getProductQty = useGetOrderProducts();
  const QtySum = getProductQty(locations);

  const totalColSpan = () => {
    return (
      6 +
      orderSummaryColumn.length +
      (!noPrice
        ? 2 + (hasDiscounts ? 1 : 0) + (separateInstall ? 1 : 0)
        : 0 + (separateInstall ? 1 : 0))
    );
  };

  const [theTable, setTheTable] = useState<HTMLTableElement | undefined>();
  const theTableRef = useCallback((node: HTMLTableElement) => {
    if (node !== null) {
      setTheTable(node);
    }
  }, []);

  const lastRowNumber = theTable?.rows.length;

  const [summary, setSummary] = useState<HTMLDivElement | null>();
  const summaryRefFunc = useCallback((node: HTMLDivElement | null) => {
    setSummary(node);
  }, []);

  const printSummary = useReactToPrint({
    // @ts-ignore
    content: () => summary,
    documentTitle: `${fullNum} ${customer.name} Summary`,
  });

  const pixelMM = 0.2645833333;

  const [height, setHeight] = useState(0);

  const currentHeight = Math.floor(height * pixelMM);

  const pageH = 210;

  const heightDiff = Math.floor(currentHeight % pageH);

  const minHeight =
    currentHeight > pageH && heightDiff > 0
      ? pageH * Math.ceil(currentHeight / pageH)
      : pageH;

  const observedDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!observedDiv.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      const newHeight = observedDiv.current?.offsetHeight;
      if (newHeight && newHeight !== height) {
        setHeight(newHeight || 0);
      }
    });

    resizeObserver.observe(observedDiv.current);

    return function cleanup() {
      resizeObserver.disconnect();
    };
  }, [observedDiv.current]);

  const isCommercial = dbOrder?.process.processType.id == 3;

  return (
    <div className="flex flex-row justify-center text-black">
      <div className="bg-white rounded-lg p-10 overflow-x-auto drop-shadow-lg">
        <div>
          <div ref={observedDiv}>
            <div
              ref={summaryRefFunc}
              className="w-[297mm] h-full relative flex flex-col overflow-y-auto select-text border-y-[10px] border-[#c1aa80] print:scrollbar-none"
              style={{
                minHeight: `${210}mm`,
              }}
            >
              <style>{getPageMargins()}</style>
              {/* Header */}
              <div className="flex flex-row relative border-x-[10px] border-[#c1aa80] h-[140px]">
                <div id="left" className="flex flex-col flex-1 pl-4 pt-3">
                  <div
                    id="companyDetail"
                    className="flex flex-row gap-4 items-end"
                  >
                    <img
                      src={isCommercial ? jncLogo : logo}
                      className="w-[114pt]"
                    />
                    <div
                      id="details"
                      className="flex flex-col justify-between text-[7pt] gap-1 text-gray-500 pt-[4px]"
                      style={{
                        lineHeight: 1,
                      }}
                    >
                      <span>
                        {isCommercial
                          ? "JnC Blinds Pty Ltd ABN : 92 603 118 494 "
                          : "EziLux Pty Ltd ABN : 12 609 089 509"}
                      </span>
                      <span>39 Parramatta Rd, Underwood QLD 4119</span>
                      <span>
                        {isCommercial
                          ? "07 3423 0330 / info@jncblinds.com.au"
                          : "1300 EziLux / 07 3085 4311 / info@ezilux.com.au"}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 font-bold text-[10pt] mt-2">
                    {customer.name}
                  </div>
                  <div className="flex flex-row gap-2 font-bold text-[10pt]">
                    {customer.contact?.number}
                  </div>
                  <div className="flex flex-row gap-2 font-bold text-[10pt]">
                    {customer.contact?.mail}
                  </div>
                  <div className="flex flex-row gap-2 font-bold text-[10pt]">
                    <span>{customer.address}</span>
                  </div>
                </div>
                <div
                  id="middle"
                  className="absolute left-1/2 -translate-x-1/2 flex flex-col h-full justify-center"
                >
                  <div className="text-center font-bold text-[26pt] capitalize">
                    {title()}
                  </div>
                </div>
                <div
                  id="right"
                  className="flex-1 flex flex-row gap-2 p-[12px] pt-4 justify-end"
                >
                  <div
                    className="flex flex-col text-[12px] font-semibold text-gray-500 items-end gap-2"
                    style={{
                      lineHeight: 1,
                    }}
                  >
                    <span>Proposal / Order #</span>
                    {dbOrder?.orderRef && <span>Ref</span>}
                    <span>Date</span>
                    {!isQuote && <span>Due</span>}
                    <span> {isQuote ? "Proposed by" : "Entered by"}</span>
                  </div>

                  <div
                    className="flex flex-col text-[12px] font-bold  items-start gap-2"
                    style={{
                      lineHeight: 1,
                    }}
                  >
                    <span>{fullNum}</span>
                    {dbOrder?.orderRef && <span>{dbOrder?.orderRef}</span>}
                    <span>{dayjs().format("DD/MM/YYYY")}</span>
                    {!isQuote && (
                      <span>
                        {dbOrder?.due
                          ? dayjs(dbOrder?.due).format("DD/MM/YYYY")
                          : "Nill"}
                      </span>
                    )}
                    <span className="capitalize">
                      {dbOrder?.user.firstName} {dbOrder?.user.sirName}
                    </span>
                    <span>
                      {dbOrder?.user.mobile ? dbOrder?.user.mobile : ""}
                    </span>
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="flex flex-row justify-center w-full py-10 border-x-[10px] border-[#c1aa80]">
                  <Spinner size="lg" color="purple" />
                </div>
              ) : (
                <>
                  <table id="summaryTable" ref={theTableRef}>
                    <thead
                      className="border-y-2 border-black text-[9pt] bg-[#c1aa80] h-[39px]"
                      style={{
                        lineHeight: 1.2,
                      }}
                    >
                      <tr>
                        <th className="w-[10px]" />
                        <th className="py-1">#</th>
                        <th className="py-1">Location</th>
                        <th className="py-1" colSpan={2}>
                          Product
                        </th>
                        <th className="py-1">W</th>
                        <th className="py-1">H</th>
                        {sort(orderSummaryColumn)
                          .asc("position")
                          .map(column => (
                            <th
                              className="max-w-[60px] px-[2px] py-1"
                              key={column.id}
                            >
                              {column.name}
                            </th>
                          ))}
                        <th>Sqm</th>
                        {!noPrice && (
                          <>
                            <th className="px-[1px] py-1">Price</th>
                            {separateInstall && (
                              <th className="px-[1px] py-1">Install</th>
                            )}
                            {hasDiscounts && (
                              <th className="px-[1px] py-1">DC</th>
                            )}
                          </>
                        )}
                        <th className="!w-[10px]" />
                      </tr>
                    </thead>
                    <tbody>
                      {openings.map((o, i) => {
                        const product = productSets.find(
                          ps => ps.id == o.product
                        );
                        if (o.product == "custom") {
                          return (
                            <CustomSummaryItem
                              hasDiscounts={hasDiscounts}
                              separateInstall={separateInstall}
                              gstInclusive={gstInclusive}
                              opening={o}
                              key={o.li + "." + i}
                              locationIndex={
                                isNullish(o.lName) ? o.li : o.lName
                              }
                              singleLocation={singleLocation}
                              noPrice={noPrice}
                            />
                          );
                        }
                        if (!product) return null;
                        if (product.hideItems) {
                          return (
                            <SummaryItem
                              hasDiscounts={hasDiscounts}
                              salesParams={salesParams}
                              separateInstall={separateInstall}
                              gstInclusive={gstInclusive}
                              locationIndex={
                                isNullish(o.lName) ? o.li : o.lName
                              }
                              singleLocation={singleLocation}
                              product={product}
                              key={o.li + "." + i}
                              opening={o}
                              noPrice={noPrice}
                            />
                          );
                        }
                        return (
                          <Fragment key={i}>
                            {o.items.map(item => (
                              <SummaryItem
                                hasDiscounts={hasDiscounts}
                                salesParams={salesParams}
                                separateInstall={separateInstall}
                                gstInclusive={gstInclusive}
                                product={product}
                                key={i + "" + item.index}
                                opening={o}
                                locationIndex={
                                  isNullish(o.lName) ? o.li : o.lName
                                }
                                singleLocation={singleLocation}
                                item={item}
                                noPrice={noPrice}
                              />
                            ))}
                          </Fragment>
                        );
                      })}
                      <tr className="h-[22px]">
                        <td className="w-[10px] bg-[#c1aa80]" />
                        <td
                          colSpan={totalColSpan()}
                          className="text-transparent"
                        >
                          {"Qty SQM Sum"}
                        </td>
                        <td className="w-[10px] bg-[#c1aa80]" />
                      </tr>
                      <tr className="h-[22px]">
                        <td className="w-[10px] bg-[#c1aa80]" />
                        <td colSpan={totalColSpan() - 4} />
                        <td
                          colSpan={2}
                          className="text-center font-semibold text-[9pt] text-[#c1aa80]"
                        >
                          Product
                        </td>
                        <td className="text-center font-semibold text-[9pt] text-[#c1aa80]">
                          Sum
                        </td>
                        <td className="text-center font-semibold text-[9pt] text-[#c1aa80]">
                          Qty
                        </td>
                        <td className="w-[10px] bg-[#c1aa80]" />
                      </tr>
                      {QtySum.map(sum => (
                        <tr key={sum.name} className="h-[22px]">
                          <td className="w-[10px] bg-[#c1aa80]" />
                          <td colSpan={totalColSpan() - 4} />
                          <td
                            colSpan={2}
                            className="text-center font-semibold text-[9pt]"
                          >
                            {sum.name}
                          </td>
                          <td className="text-center font-semibold text-[9pt]">
                            {sum.summaryQty > 0
                              ? sum.summaryQty.toFixed(1)
                              : sum.sqm.toFixed(2)}{" "}
                            {sum.summaryQty > 0 ? sum.unit : "sqm"}
                          </td>
                          <td className="text-center font-semibold text-[9pt]">
                            {sum.qty}
                          </td>
                          <td className="w-[10px] bg-[#c1aa80]" />
                        </tr>
                      ))}
                      {exposedMatsPopulated.length > 0 && (
                        <>
                          <tr className="h-[22px]">
                            <td className="w-[10px] bg-[#c1aa80]" />
                            <td colSpan={totalColSpan()} />
                            <td className="w-[10px] bg-[#c1aa80]" />
                          </tr>
                          <tr className="h-[22px]">
                            <td className="w-[10px] bg-[#c1aa80]" />
                            <td colSpan={totalColSpan() - 4} />
                            <td
                              colSpan={2}
                              className="text-center font-semibold text-[9pt] text-[#c1aa80]"
                            >
                              Req Mat
                            </td>
                            <td className="text-center font-semibold text-[9pt] text-[#c1aa80]">
                              Qty
                            </td>
                            <td className="text-center font-semibold text-[9pt] text-[#c1aa80]">
                              Unit
                            </td>
                            <td className="w-[10px] bg-[#c1aa80]" />
                          </tr>
                        </>
                      )}
                      {exposedMatsPopulated.map((em, i) => (
                        <tr key={i} className="h-[22px]">
                          <td className="w-[10px] bg-[#c1aa80]" />
                          <td colSpan={totalColSpan() - 4} />
                          <td
                            colSpan={2}
                            className="text-center font-semibold text-[9pt]"
                          >
                            {em.name}
                          </td>
                          <td className="text-center font-semibold text-[9pt]">
                            {em.qty.toFixed(1)}
                          </td>
                          <td className="text-center font-semibold text-[9pt]">
                            {em.unit}
                          </td>
                          <td className="w-[10px] bg-[#c1aa80]" />
                        </tr>
                      ))}
                      {!noPrice && isRetail && diffMinCharge() > 0 && (
                        <tr className="h-[22px]">
                          <td className="w-[10px] bg-[#c1aa80]" />
                          <td />
                          <td
                            className="px-2 text-[9pt] font-semibold text-right"
                            colSpan={totalColSpan() - 2}
                          >
                            Minimum Installation Fee
                          </td>
                          <td className="px-2 text-[9pt] font-semibold text-center">
                            {currencyFormat(
                              gstInclusive
                                ? Number((diffMinCharge() * 1.1).toFixed(1))
                                : diffMinCharge()
                            )}
                          </td>
                          <td className="w-[10px] bg-[#c1aa80]" />
                        </tr>
                      )}
                      {!noPrice && !!blindCutFeeSurcharge && (
                        <tr className="h-[22px]">
                          <td className="w-[10px] bg-[#c1aa80]" />
                          <td />
                          <td
                            className="px-2 text-[9pt] font-semibold text-right"
                            colSpan={totalColSpan() - 2}
                          >
                            Minimum Fabric Cut Charge
                          </td>
                          <td className="px-2 text-[9pt] font-semibold text-center">
                            {currencyFormat(
                              gstInclusive
                                ? Number(
                                    (blindCutFeeSurcharge * 1.1).toFixed(1)
                                  )
                                : blindCutFeeSurcharge
                            )}
                          </td>
                          <td className="w-[10px] bg-[#c1aa80]" />
                        </tr>
                      )}
                      {!noPrice && isRetail && (
                        <tr className="h-[22px]">
                          <td className="w-[10px] bg-[#c1aa80]" />
                          <td />
                          <td
                            className="px-2 text-[9pt] font-semibold text-right"
                            colSpan={totalColSpan() - 3}
                          >
                            Measure Fee
                          </td>
                          <td />
                          <td className="px-2 text-[9pt] font-semibold text-center">
                            {isMeasureIncluded
                              ? currencyFormat(
                                  gstInclusive
                                    ? Number((measureCharge * 1.1).toFixed(1))
                                    : measureCharge
                                ).replace(".00", "")
                              : "N/I"}
                          </td>
                          <td className="w-[10px] bg-[#c1aa80]" />
                        </tr>
                      )}
                      {notes.length > 0 && (
                        <>
                          <tr className="!bg-[#c1aa80] h-[22px]">
                            <td className="w-[10px] bg-[#c1aa80]" />
                            <td
                              className="text-center font-semibold py-1"
                              colSpan={totalColSpan()}
                            >
                              Notes
                            </td>
                            <td className="w-[10px] bg-[#c1aa80]" />
                          </tr>
                          {notes.map((note, i) => (
                            <tr key={i} className="h-[22px]">
                              <td className="w-[10px] bg-[#c1aa80]" />
                              <td className="px-1 text-center text-[9pt] font-semibold">
                                {note.index}
                              </td>
                              <td
                                className="px-2 text-[9pt] font-semibold"
                                colSpan={totalColSpan() - 1}
                              >
                                {note.note}
                              </td>
                              <td className="w-[10px] bg-[#c1aa80]" />
                            </tr>
                          ))}
                        </>
                      )}
                      {remarks.trim() !== "" && (
                        <>
                          <tr className="!bg-[#c1aa80] h-[22px]">
                            <td className="w-[10px] bg-[#c1aa80]" />
                            <td
                              className="text-center font-semibold py-1"
                              colSpan={totalColSpan()}
                            >
                              Remarks
                            </td>
                            <td className="w-[10px] bg-[#c1aa80]" />
                          </tr>
                          <tr className="h-[22px] bg-transparent!">
                            <td className="w-[10px] bg-[#c1aa80]" />
                            <td
                              className="px-2 text-[9pt] font-semibold whitespace-pre-wrap"
                              colSpan={totalColSpan()}
                            >
                              {remarks}
                            </td>
                            <td className="w-[10px] bg-[#c1aa80]" />
                          </tr>
                        </>
                      )}
                      {additionalMats && additionalMats.length > 0 && (
                        <>
                          <tr className="h-[22px]">
                            <td className="w-[10px] bg-[#c1aa80]" />
                            <td
                              className="text-center font-semibold bg-[#c1aa80] py-1"
                              colSpan={totalColSpan()}
                            >
                              Additional Components & Materials
                            </td>
                            <td className="w-[10px] bg-[#c1aa80]" />
                          </tr>
                          <tr className="h-[22px]">
                            <td className="w-[10px] bg-[#c1aa80]" />
                            <td />
                            <td
                              colSpan={totalColSpan() - 4}
                              className="text-center font-semibold text-[10pt]"
                            >
                              Material
                            </td>
                            <td className="text-center font-semibold text-[10pt]">
                              Qty
                            </td>
                            <td className="text-center font-semibold text-[10pt]">
                              Unit
                            </td>

                            {!noPrice && (
                              <td className="text-center font-semibold text-[10pt]">
                                Price
                              </td>
                            )}
                          </tr>

                          {additionalMats.map((mat, i) => {
                            const material = materials.find(
                              m => m.id == mat.id
                            );
                            if (!material) return null;
                            const unit = units.find(u => u.id == mat.unit);
                            const price = material.prices.find(
                              p => p.unit == mat.unit
                            )?.price;
                            return (
                              <tr key={i} className="h-[22px]">
                                <td className="w-[10px] bg-[#c1aa80]" />
                                <td />
                                <td
                                  colSpan={totalColSpan() - 4}
                                  className="text-center font-semibold text-[9pt]"
                                >
                                  {[
                                    material?.brand,
                                    material?.name,
                                    material?.color,
                                    material?.size,
                                  ]
                                    .filter(m => m !== null)
                                    .join(" ")}
                                </td>
                                <td className="text-center font-semibold text-[9pt]">
                                  {mat.qty}
                                </td>
                                <td className="text-center font-semibold text-[9pt]">
                                  {unit?.name}
                                </td>

                                {!noPrice && (
                                  <td className="text-center font-semibold text-[9pt]">
                                    {price &&
                                      currencyFormat(
                                        gstInclusive
                                          ? Number(
                                              (price * 1.1 * mat.qty).toFixed(1)
                                            )
                                          : price * mat.qty
                                      )}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </tbody>
                  </table>
                  <div
                    id="filler"
                    className={`px-[11px] overflow-clip border-x-[10px] border-[#c1aa80] ${
                      Number(lastRowNumber) % 2 == 1
                        ? "filler-even"
                        : "filler-odd"
                    }`}
                    style={{
                      flex: "1 1 0",
                    }}
                  />
                  {!noPrice && (
                    <div className="flex flex-row mx-[10px] border-t-[6px] border-double border-black">
                      <div className="flex-1 flex flex-col py-2 px-4">
                        <div className="text-center font-bold">
                          Acknowledgement & Acceptance of Order
                        </div>
                        <div className="flex flex-row gap-2 items-center">
                          {/* Acknowledgement */}
                          <div
                            className="text-center text-[8pt] mt-2 px-4 flex-1"
                            style={{ lineHeight: 1.3 }}
                          >
                            I Acknowledge receipt and acceptance of the Terms &
                            Conditions available at{" "}
                            <a
                              href={
                                isCommercial
                                  ? "www.jncblinds.com.au/terms-conditions"
                                  : "www.ezilux.com.au/terms-conditions"
                              }
                            >
                              <span className="underline">
                                {isCommercial
                                  ? "www.jncblinds.com.au/terms-conditions:"
                                  : "www.ezilux.com.au/terms-conditions"}
                              </span>
                            </a>{" "}
                            {!isCommercial && "or by scanning the QR code."}I I
                            understand and commit to complying with all Terms
                            and Conditions procedures. The quotation is valid
                            for 30 days, and careful review of the details above
                            is advised before proceeding. A 50% deposit is
                            required to initiate the process, with the remaining
                            amount due before installation/delivery. Refunds for
                            deposit are not possible once an order is placed and
                            manufacturing begins. A $299 cancellation fee
                            applies after the final check measure. The
                            installation of a product within the window reveal
                            or recess may impede the removal of window/door
                            panels for cleaning or other purposes.{" "}
                            {isCommercial ? "JnC" : "Ezilux"} disclaim
                            responsibility for any such impediment. Call out fee
                            applies after 30 days, please see T & C for more
                            information.
                          </div>
                          {/* QR */}
                          <img src={qr} className="w-24" />
                        </div>
                        <div className="flex flex-row justify-between items-end">
                          <div className="flex flex-col items-start">
                            <span
                              className="text-center text-[8pt] font-bold"
                              style={{ lineHeight: 1.3 }}
                            >
                              {isCommercial ? "JnC Blinds " : "EziLux "}
                              Remittance details
                            </span>
                            <span
                              className="text-center text-[8pt] font-bold"
                              style={{ lineHeight: 1.3 }}
                            >
                              {isCommercial
                                ? "BSB 064 170 ACC 1065 1783"
                                : "BSB 064 170 ACC 1069 5866"}
                              :
                            </span>
                            <span
                              className="text-center text-[8pt] font-bold"
                              style={{ lineHeight: 1.3 }}
                            >
                              Please make the payment with your proposal number.
                            </span>
                          </div>
                          <div className="flex flex-row justify-end font-bold">
                            <span>Signature :</span>
                            <span className="w-24" />
                            <span>Date :</span>
                            <span className="w-24" />
                          </div>
                        </div>
                      </div>
                      <div className="border-b-2 border-x-2 border-black flex flex-col w-52">
                        <div className="flex flex-col gap-1 py-2 px-4 text-blue-500 font-semibold min-h-[100px] justify-around">
                          <div className="flex flex-row">
                            <div
                              className="flex-[2] text-right text-[9pt]"
                              style={{ lineHeight: 1.3 }}
                            >
                              Total
                            </div>
                            <div
                              className="flex-[3] text-right text-[9pt]"
                              style={{ lineHeight: 1.3 }}
                            >
                              {currencyFormat(
                                gstInclusive
                                  ? Number((subTotal * 1.1).toFixed(1))
                                  : subTotal
                              )}
                            </div>
                          </div>
                          {hasDiscounts && (
                            <>
                              <div className="flex flex-row text-red-500">
                                <div
                                  className="flex-[2] text-right text-[9pt]"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  DC
                                </div>
                                <div
                                  className="flex-[3] text-right text-[9pt]"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  {incDC &&
                                    currencyFormat(
                                      gstInclusive
                                        ? Number((incDC * 1.1).toFixed(1))
                                        : incDC
                                    )}
                                  {Number(finalDC) !== 0 && incDC ? "+" : ""}
                                  {Number(finalDC) !== 0 &&
                                    `$${
                                      gstInclusive
                                        ? Number((finalDC * 1.1).toFixed(0))
                                        : finalDC
                                    }`}
                                </div>
                              </div>
                              <div className="flex flex-row font-extrabold">
                                <div
                                  className="flex-[2] text-right text-[9pt]"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  After DC
                                </div>
                                <div
                                  className="flex-[3] text-right text-[9pt]"
                                  style={{ lineHeight: 1.3 }}
                                >
                                  {currencyFormat(
                                    gstInclusive
                                      ? Number((afterDiscount * 1.1).toFixed(1))
                                      : afterDiscount
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                          <div className="flex flex-row">
                            <div
                              className="flex-[2] text-right text-[9pt]"
                              style={{ lineHeight: 1.3 }}
                            >
                              GST{gstInclusive ? "(inc)" : ""}
                            </div>
                            <div
                              className="flex-[3] text-right text-[9pt]"
                              style={{ lineHeight: 1.3 }}
                            >
                              {currencyFormat(gst)}
                            </div>
                          </div>
                          <div className="flex flex-row font-extrabold">
                            <div
                              className="flex-[2] text-right text-[9pt]"
                              style={{ lineHeight: 1.3 }}
                            >
                              Grand Total
                            </div>
                            <div
                              className="flex-[3] text-right text-[9pt]"
                              style={{ lineHeight: 1.3 }}
                            >
                              {currencyFormat(total)}
                            </div>
                          </div>
                        </div>
                        <div
                          className="border-y-2 border-black p-1 text-center text-[6pt]"
                          style={{
                            lineHeight: 1,
                          }}
                        >
                          *Above price is subject to final check measure.
                        </div>
                        <div className="bg-[#c1aa80] px-4 flex flex-row justify-between items-center flex-1">
                          <div
                            className="font-bold text-[9pt]"
                            style={{ lineHeight: 1 }}
                          >
                            Deposit required
                          </div>
                          <div
                            className="font-bold text-[9pt]"
                            style={{ lineHeight: 1 }}
                          >
                            {currencyFormat(Math.ceil(total / 2))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="absolute top-0 left-0 w-full h-full -z-10 border-x-[10px] border-[#c1aa80]" />
            </div>
          </div>
          <div className="flex flex-row justify-end items-center gap-2 no-print mt-4">
            {cancelFunc && (
              <Button
                onClick={cancelFunc}
                size="sm"
                color="purple"
                className="w-fit"
              >
                Cancel
              </Button>
            )}
            <Button
              onClick={printSummary}
              size="sm"
              gradientDuoTone="purpleToBlue"
              outline
              className="w-fit"
            >
              Print
            </Button>
            {canConfirm && (
              <Button
                onClick={submitOrder}
                size="sm"
                gradientDuoTone="purpleToBlue"
                className="w-fit"
              >
                Confirm and submit order
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

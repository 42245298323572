import {
  Badge,
  Button,
  Label,
  ListGroup,
  Modal,
  Select,
  Spinner,
  TextInput,
  Textarea,
  ToggleSwitch,
} from "flowbite-react";
import {
  useForm,
  FormProvider,
  useWatch,
  useFormContext,
} from "react-hook-form";
import Heading from "../../../../comps/heading";
import Locations from "./pages/ordering/locations";
import {
  AdjustmentsVerticalIcon,
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  CloudArrowUpIcon,
  EyeIcon,
  EyeSlashIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { order } from "./types";
import { useEffect, useRef, useState } from "react";
import Actions from "./actions/actions";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { fullNumber } from "../../../../utils/fullNumber";
import { GET_PROCESS_BY_PK } from "../../process/processDetail/processDetail";
import { NewProcessType, ProcessType } from "../../process/types";
import { OrderType } from "../types";
import { ADD_ORDER, GET_ORDER_BY_PK, UPDATE_ORDER_BY_PK } from "../gqls";
import { WORKORDER_CORE_FIELDS } from "../fragments";
import { useAuthStore } from "../../../../store/authStore";
import dayjs from "dayjs";
import checkAuth from "../../../../utils/checkAuth";
import { useScheme } from "../../../../store/schemeStore";
import {
  Autocomplete,
  AutocompleteProps,
  LoadScriptProps,
  useLoadScript,
} from "@react-google-maps/api";
const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY;
const googleMapsLibraries: LoadScriptProps["libraries"] = ["places"];
import { default as configs } from "../../../../config";
import { useDebounce } from "usehooks-ts";
import { SEARCH_CONTACT } from "../../process/addProcess/contact";
import { ContactType, NewContactType } from "../../contact/types";
import useAddContact from "../../contact/addContact/useAddContact";
import { GET_CONTACT_CONDITIONAL } from "../../contact/gqls";
import { GET_PROCESS_LAST_NUM } from "../../process/addProcess/ids";
import { INSERT_PROCESS } from "../../process/addProcess/addProcess";
import { PROCESS_CORE_FIELDS } from "../../process/fragments";
import { addAlert } from "../../../../store/alertStore";
import getContrast from "../../../../utils/getContrast";
import { GET_RELEASED_ORDERS_CONDITIONAL } from "../orderRelease/gqls";
import { GET_PRODUCTIONS_CONDITIONAL_MIN } from "../../production/gql";
import { useOrderStore } from "../../../../store/orderStore";
import SummaryPage from "./pages/summary/summaryPage";
import PrintPage from "./pages/orderPrint/printPage";
import { ADD_ASSIGNMENT_ORDER_BY_PK } from "../../assignment/gqls";
import { GET_PROCESS_TYPES } from "../../settings/process/processTypes/processTypes";
import AdditionalMats from "./additionalMats";
import QuoteParams from "./pages/quoteParams/quoteParams";
const companyLoc = configs.companyLoc;

// TODO : Undo, Redo function

interface firstSaveValues {
  address: string;
  postCode: string;
  contactUseMe: boolean;
  contact: {
    name: string;
    number: string;
    email: string;
  };
  exContact?: ContactType;
  isDraft: boolean;
  processType: number;
}

export default function EditOrder() {
  const { processId, orderId, assignmentId, type } = useParams();
  const { productSets: ProductSets, orderTypes } = useOrderStore();

  const { user } = useAuthStore();

  const defaultValues: order = {
    locations: [],
    additionalMats: [],
    imagesOnOver: true,
    showOptionals: false,
    showCalcs: false,
    showNotes: true,
    name: "",
    due: "",
    description: "",
    orderRef: "",
    user: user?.id,
    type: orderTypes.find(t => t.name == type),
    salesParams: undefined,
  };

  const methods = useForm({
    defaultValues,
  });

  const { getValues, reset } = methods;

  const didMountRef = useRef(false);

  const { data: process_data, loading: loadingProduction } = useQuery(
    GET_PROCESS_BY_PK,
    {
      variables: {
        id: processId,
      },
    }
  );
  const process = process_data?.process_by_pk;

  const [loadedOrder, setLoadedOrder] = useState<undefined | number>(undefined);

  const { data: workorder_data, loading: loadingWorkOrder } = useQuery(
    GET_ORDER_BY_PK,
    {
      variables: {
        id: orderId,
      },
      onCompleted(data) {
        if (data.workorder_by_pk) {
          const isDiffOrder = loadedOrder !== data.workorder_by_pk.id;
          setLoadedOrder(data.workorder_by_pk.id);
          const {
            locations,
            imagesOnOver,
            showOptionals,
            showCalcs,
            additionalMats,
            salesParams,
          } = data.workorder_by_pk.order;
          const { due, description, orderRef } = data.workorder_by_pk;

          const prevLocs = locations.map(l => ({
            ...l,
            openings: l.openings.map(o => ({
              ...o,
              preview: false,
              items: o?.items?.map(i => ({ ...i, locked: true })),
            })),
          }));

          const exOrder = {
            locations: prevLocs,
            additionalMats,
            imagesOnOver,
            showOptionals,
            showCalcs,
            due: due ? dayjs(due).format("YYYY-MM-DD") : undefined,
            description,
            name: process?.name,
            orderRef,
            user: data.workorder_by_pk.createdBy,
            type: data.workorder_by_pk.type,
            salesParams,
          };
          didMountRef.current = true;

          if (isDiffOrder) {
            reset(exOrder);
          }
        }
      },
    }
  );

  const order = workorder_data?.workorder_by_pk;

  const [update] = useMutation(UPDATE_ORDER_BY_PK);

  const [modal, setModal] = useState(false);

  const saveForm = async () => {
    if (!process) {
      setModal(true);
      return;
    }
    if (!order) {
      return;
    }
    if (didMountRef.current) {
      setSaving(true);
      const data = getValues();
      const order = {
        order: {
          locations: data.locations,
          additionalMats: data.additionalMats,
          imagesOnOver: false,
          showOptionals: false,
          showCalcs: false,
          salesParams: data.salesParams,
        },
        due: data.due ? dayjs(data.due).format("YYYY-MM-DD") : null,
        description: data.description,
        orderRef: data.orderRef,
        lastUpdateBy: user?.id,
      };
      update({
        variables: {
          id: orderId,
          set: order,
        },
        onCompleted() {
          setSaving(false);
        },
        onError(error) {
          console.log(error);
        },
      });
    }
  };

  const [saving, setSaving] = useState(false);
  const [insert] = useMutation(ADD_ORDER);

  const navigate = useNavigate();

  const [add_order_to_assignment] = useMutation(ADD_ASSIGNMENT_ORDER_BY_PK);

  const uploadOrder = (processId?: number, isDraft?: boolean) => {
    if (!processId) {
      return;
    }
    setSaving(true);
    const data = getValues();
    const order = {
      due: data.due ? dayjs(data.due).format("YYYY-MM-DD") : null,
      orderRef: data.orderRef,
      description: data.description || "",
      order: data,
    };

    insert({
      variables: {
        object: {
          ...order,
          createdBy: user?.id,
          processId,
          typeId: orderTypes.find(t => t.name == type)?.id,
          statusId: isDraft == undefined ? 1 : isDraft ? 1 : 2,
          lastUpdateBy: user?.id,
        },
      },
      onCompleted(data) {
        const id = data.insert_workorder_one.id;
        setSaving(false);
        setModal(false);
        if (assignmentId) {
          add_order_to_assignment({
            variables: {
              id: assignmentId,
              orderId: id,
            },
          });
        }
        navigate(`/order/edit/${processId}/${id}`, { replace: true });
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            workorder(existing = []) {
              const newRef = cache.writeFragment({
                data: data?.insert_workorder_one,
                fragment: WORKORDER_CORE_FIELDS,
                fragmentName: "WorkorderCoreFields",
              });
              return [...existing, newRef];
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    if (!order && !loadingWorkOrder) {
      if (saving) {
        return;
      }
      uploadOrder(Number(processId));
    }
  }, [order, loadingWorkOrder]);

  const { addNewContact } = useAddContact();

  const [getContact] = useLazyQuery(GET_CONTACT_CONDITIONAL);
  const [getLastNum] = useLazyQuery(GET_PROCESS_LAST_NUM);
  const [insert_process, { loading: inserting }] = useMutation(INSERT_PROCESS);

  const addNewProcessAndOrder = async (data: firstSaveValues) => {
    const orderData = getValues();
    const contacts: number[] = [];

    const processContact = async () => {
      if (data.contactUseMe && user) {
        const { data: myContactData } = await getContact({
          variables: {
            where: {
              _and: [
                { name: { _eq: `${user.firstName} ${user.sirName}` } },
                { mail: { _eq: user.email } },
                { number: { _eq: user.mobile } },
              ],
            },
          },
        });

        const myContact = myContactData?.contacts && myContactData.contacts[0];

        if (myContact) {
          contacts.push(myContact.id);
        } else {
          const newContactData: NewContactType = {
            name: `${user.firstName} ${user.sirName}`,
            typeID: 1,
            address: user.address,
            mail: user.email,
            number: user.mobile || undefined,
          };
          const newContact: ContactType = await addNewContact(newContactData);
          if (newContact?.id) {
            contacts.push(newContact.id);
          }
          return;
        }
      }
      if (data.exContact) {
        contacts.push(data.exContact.id);
        return;
      } else {
        const newContactData: NewContactType = {
          name: data.contact.name,
          typeID: 1,
          address: data.address,
          mail: data.contact.email,
          number: data.contact.number,
        };

        const newContact: ContactType = await addNewContact(newContactData);
        if (newContact?.id) {
          contacts.push(newContact.id);
        }
        return;
      }
    };
    await processContact();

    const locations = orderData.locations.map((l, i) => ({
      ...l,
      index: i + 1,
      openings: l.openings.map((o, ix) => ({
        ...o,
        items: o.items.map((item, iz) => ({ ...item, index: iz + 1 })),
        index: ix + 1,
      })),
    }));
    const openings = locations?.map(l => l.openings).flat();
    const products = openings?.reduce<{ productId: number; qty: number }[]>(
      (prev, cur) => {
        const Product = ProductSets?.find(p => p.id == cur.product);
        if (!Product) {
          return prev;
        }

        const productId = Product?.product.id;
        const isOpeningProduct = Product.hideItems;
        const qty = isOpeningProduct ? 1 : cur.items.length;

        if (prev.find(p => p.productId == productId)) {
          return prev.map(pro => {
            if (pro.productId == productId) {
              return { ...pro, qty: pro.qty + qty };
            } else return pro;
          });
        } else {
          return prev.concat({
            qty,
            productId: Product.product.id,
          });
        }
      },
      []
    );

    const { data: lastProcessData } = await getLastNum({
      variables: {
        typeId: 2,
        year: new Date().getFullYear(),
      },
      fetchPolicy: "no-cache",
    });

    let number;
    if (lastProcessData?.process && lastProcessData?.process.length > 0) {
      number = lastProcessData?.process[0].number + 1;
    } else {
      number = 1;
    }

    const isDraft = data.isDraft;

    const newProcess: NewProcessType = {
      // @ts-ignore
      contacts,
      name:
        orderData.name !== ""
          ? orderData.name
          : `${user?.firstName} ${user?.sirName}`,
      due: orderData.due ? new Date(orderData.due) : null,
      year: new Date().getFullYear(),
      number,
      typeId: Number(data.processType),
      products,
      status: isDraft ? 1 : 8,
      address: data.address,
      postCode: Number(data.postCode),
      description: orderData.description,
      from: null,
      createdBy: user?.id,
    };

    insert_process({
      variables: newProcess,
      update(cache, { data: { insert_process_one: newProcess } }) {
        cache.modify({
          fields: {
            process(existingProcesss = []) {
              const newProcessRef = cache.writeFragment({
                data: newProcess,
                fragment: PROCESS_CORE_FIELDS,
                fragmentName: "ProcessCoreFields",
              });
              return [...existingProcesss, newProcessRef];
            },
          },
        });
      },
      onCompleted: data => {
        const id = data.insert_process_one?.id;
        if (id) {
          uploadOrder(id, isDraft);
        }
      },
      onError(error) {
        console.log(error);
        addAlert({
          message: "something went wrong cannot add process",
          type: "failure",
        });
      },
    });
  };

  const [page, setPage] = useState("ordering");

  const editDisabled =
    !checkAuth("order_override") &&
    order &&
    order.status.priority > 0 &&
    order?.type.permission &&
    !checkAuth(order.type.permission);

  useEffect(() => {
    if (editDisabled) {
      setPage("printing");
    }
  }, [editDisabled]);

  const resetPage = () => {
    setPage("ordering");
  };

  const availableOrderTypes = orderTypes.filter(t => {
    if (t.permission) {
      return checkAuth(t.permission);
    }
    return false;
  });

  const title = () => {
    if (!order?.id) {
      return `New ${type}`;
    }

    if (order.id) {
      return `Edit ${order.type.name}`;
    }

    return `Edit ${availableOrderTypes.join(" / ")}`;
  };

  if (loadingProduction) {
    return null;
  }

  return (
    <main className="select-none flex flex-col flex-1 pb-10">
      {/* Header Part */}
      <div className="px-6 mb-1">
        <div className="flex flew-row justify-between h-8">
          <Heading name={title()} />
        </div>
        <div className="flex flex-row text-grass mb-10">
          calculate product sizes & materials for measure/quote/order
        </div>

        <section className="flex flex-col gap-2">
          <FormProvider {...methods}>
            <form>
              <OrderMetaData process={process} order={order} />

              <div className={`${page !== "ordering" && "hidden"}`}>
                <OrderActions
                  type={order?.type.name || (type as string)}
                  defaultValues={defaultValues}
                  process={process}
                  saving={saving}
                  saveForm={saveForm}
                  setPage={setPage}
                />
                <Locations />
                <AdditionalMats />
              </div>
              {page == "quoteParams" && (
                <QuoteParams dbOrder={order} setPage={setPage} />
              )}

              {page == "printing" && (
                <PrintPage
                  dbOrder={order}
                  cancelFunc={editDisabled ? () => {} : resetPage}
                />
              )}
              {page == "orderConfirm" && (
                <div className="flex flex-col">
                  <hr className="mt-2 mb-4" />
                  <SummaryPage
                    dbOrder={order}
                    canConfirm={true}
                    cancelFunc={resetPage}
                  />
                </div>
              )}
              <Actions
                processId={processId}
                setModal={setModal}
                dbOrder={workorder_data?.workorder_by_pk}
                setPage={setPage}
                saveForm={saveForm}
              />
            </form>
          </FormProvider>
          <FirstSave
            modal={modal}
            setModal={setModal}
            addNew={addNewProcessAndOrder}
          />
        </section>
      </div>
    </main>
  );
}

interface firstSaveprops {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  addNew: (data: any) => void;
}

const FirstSave = ({ modal, setModal, addNew }: firstSaveprops) => {
  const { scheme } = useScheme();
  const { type } = useParams();
  const { user } = useAuthStore();

  const { data: data_process_types } = useQuery(GET_PROCESS_TYPES);
  const processTypes = data_process_types?.processType || [];
  const userProcessTypes = user?.team?.processTypes || [];

  const defaultValues: firstSaveValues = {
    address: "",
    postCode: "",
    contactUseMe: false,
    contact: {
      name: "",
      number: "",
      email: "",
    },
    exContact: undefined,
    isDraft: type == "order",
    processType: 2,
  };

  const { register, setValue, getValues, control } = useForm({
    defaultValues,
  });

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries: googleMapsLibraries,
  });

  const [autocomplete, setAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null);

  const autoCompleteLoaded = (
    autocomplete: google.maps.places.Autocomplete
  ) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      const { formatted_address: address, address_components } = place;
      if (address) {
        setValue("address", address.replace(", Australia", "").trim());
      }
      const postCode = address_components
        ? address_components[address_components.length - 1].long_name
        : null;

      if (postCode) {
        setValue("postCode", postCode);
      }
    }
  };

  const autocompleteOptions: AutocompleteProps["options"] = {
    componentRestrictions: { country: "au" },
    bounds: {
      north: companyLoc.lat + 3,
      south: companyLoc.lat - 3,
      east: companyLoc.lng + 3,
      west: companyLoc.lng - 3,
    },
  };

  const contactUseMe: boolean = useWatch({
    name: "contactUseMe",
    control,
  });
  const toggleContactUseMe = () => {
    setValue("contactUseMe", !contactUseMe);
    if (!contactUseMe && user?.address) {
      setValue("address", user.address);

      const rx = /[0-9]{4}/g;
      const matches = rx.exec(user.address);
      if (matches && matches[0]) {
        setValue("postCode", matches[0]);
      }
    }
  };

  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!init && !contactUseMe && user?.address) {
      toggleContactUseMe();
      setInit(true);
    }
  }, []);

  const contactName: string = useWatch({
    name: "contact.name",
    control,
  });

  const exContact: ContactType | undefined = useWatch({
    name: "exContact",
    control,
  });

  const [searching, setSearching] = useState(false);

  const debouncedContactName = useDebounce<string>(contactName, 500);
  const [search_contact, { loading: search_loading, data: search_data }] =
    useLazyQuery(SEARCH_CONTACT, { fetchPolicy: "no-cache" });

  useEffect(() => {
    search_contact({
      variables: { Str: debouncedContactName },
    });
    setSearching(true);
  }, [debouncedContactName]);

  const search_results = search_data ? search_data.search_contacts || [] : [];

  const isDraft: boolean = useWatch({
    name: "isDraft",
    control,
  });
  const toggleIsDraft = () => {
    setValue("isDraft", !isDraft);
  };

  const contactClick = (contact: ContactType) => {
    setValue("exContact", contact);
    setValue("contact", {
      email: contact.mail || "",
      name: contact.name,
      number: contact.number || "",
    });
    setSearching(false);
  };

  const clearContact = () => {
    setValue("exContact", undefined);
    setValue("contact", {
      email: "",
      name: "",
      number: "",
    });
    setSearching(false);
  };

  return (
    <Modal
      show={modal}
      onClose={() => {
        setModal(false);
      }}
      className={`
    ${scheme === "dark" ? "dark" : ""} !h-full !items-start`}
    >
      <Modal.Header
        className={`${scheme == "dark" && "bg-gray-800"} capitalize`}
      >
        {isDraft ? "Save" : "Submit"} {type}
      </Modal.Header>
      <Modal.Body className={`${scheme == "dark" && "bg-gray-800"}`}>
        <div className="flex flex-col gap-2">
          {userProcessTypes.length > 0 && (
            <div className="flex flex-col gap-2">
              <Label value="Process Type" />
              <Select sizing="sm" {...register("processType")}>
                {userProcessTypes.map(pt => (
                  <option key={pt} value={pt}>
                    {processTypes.find(t => t.id == pt)?.name}
                  </option>
                ))}
              </Select>
            </div>
          )}
          {user?.mobile && (
            <ToggleSwitch
              checked={contactUseMe}
              onChange={toggleContactUseMe}
              label="Use my detail"
              // @ts-expect-error
              color={`${contactUseMe ? "purple" : "gray"}`}
            />
          )}
          <Label value="Address" />
          {isLoaded && (
            <Autocomplete
              onLoad={autoCompleteLoaded}
              onPlaceChanged={onPlaceChanged}
              options={autocompleteOptions}
              fields={[
                "formatted_address",
                "geometry.location",
                "address_component",
              ]}
              className="flex-[2]"
            >
              <TextInput sizing="sm" {...register("address")} />
            </Autocomplete>
          )}
          <Label value="Post Code" />
          <TextInput sizing="sm" {...register("postCode")} />
          {!contactUseMe && (
            <div className="relative flex flex-col gap-2">
              <Label value="Name" />
              <div className="flex flex-row w-full items-center gap-2">
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={exContact ? true : false}
                  className="flex-1"
                  {...register("contact.name")}
                />
                {exContact && (
                  <XMarkIcon
                    className="w-5 cursor-pointer"
                    onClick={clearContact}
                  />
                )}
              </div>
              {searching && !exContact && search_results.length > 0 && (
                <>
                  <XMarkIcon
                    className="w-5 absolute top-16 mt-2 right-2 cursor-pointer z-20"
                    onClick={() => {
                      setSearching(false);
                    }}
                  />
                  <ListGroup className="w-full top-16 absolute h-44 overflow-y-auto scrollbar-none z-10">
                    {search_results.map(result => (
                      <ListGroup.Item
                        key={result.id}
                        onClick={() => {
                          contactClick(result);
                        }}
                      >
                        <div className="flex flex-row items-center justify-between w-full">
                          <div className="truncate flex-1 w-0 text-left">
                            {result.name}
                            {result.number && `- ${result.number}`}
                          </div>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </>
              )}
              <Label value="Mobile" />
              <TextInput
                sizing="sm"
                type="tel"
                disabled={exContact ? true : false}
                {...register("contact.number")}
              />
              <Label value="Email" />
              <TextInput
                sizing="sm"
                type="email"
                disabled={exContact ? true : false}
                {...register("contact.email")}
              />
            </div>
          )}
          {/* {type == "order" && (
            <>
              <ToggleSwitch
                checked={isDraft}
                onChange={toggleIsDraft}
                label="Save as draft"
                // @ts-expect-error
                color={`${isDraft ? "purple" : "gray"}`}
              />
              {!isDraft && (
                <>
                  <Label value="Disclaimer" />
                  <div
                    className={`${
                      scheme == "dark" ? "text-white" : "text-black"
                    }`}
                  >
                    By submiting the order you hereby acknowledge that you have
                    read and agree to the{" "}
                    <a className="text-blue-500 underline">
                      terms and conditions
                    </a>
                    .
                  </div>
                </>
              )}
            </>
          )} */}
        </div>
      </Modal.Body>
      <Modal.Footer
        className={`${scheme == "dark" && "bg-gray-800"} justify-end`}
      >
        <Button
          color="purple"
          size="sm"
          onClick={() => {
            setModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          gradientDuoTone="purpleToBlue"
          outline
          size="sm"
          onClick={() => {
            addNew(getValues());
          }}
        >
          {isDraft ? "Save" : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

interface orderMetaData {
  process?: ProcessType;
  order?: OrderType;
}

const OrderMetaData = ({ process, order }: orderMetaData) => {
  const { register } = useFormContext();

  const navigate = useNavigate();
  const goToProcess = () => {
    const check = checkAuth("process_access");
    if (check && process) {
      navigate(`/process/detail/${process?.id}/prog`);
    }
  };

  const { data: data_releasedOrders } = useQuery(
    GET_RELEASED_ORDERS_CONDITIONAL,
    {
      variables: {
        where: {
          orderId: { _eq: order?.id },
        },
      },
    }
  );
  const releasedOrders = data_releasedOrders?.releasedOrders;

  const { data: data_productions } = useQuery(GET_PRODUCTIONS_CONDITIONAL_MIN, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        id: { _in: releasedOrders?.map(ro => ro.productionId) },
      },
    },
  });

  const productions = data_productions?.productions;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between items-end">
        <div className="flex flex-col gap-2">
          {process ? (
            <h2>{process.name}</h2>
          ) : (
            <TextInput
              sizing="sm"
              addon="customer"
              {...register("name")}
              placeholder="enter customer name"
            />
          )}
        </div>
        <div
          className="cursor-pointer opacity-50 hover:text-plum"
          onClick={goToProcess}
        >
          {process
            ? fullNumber(
                process.processType.prefix,
                process.year,
                process.number,
                process.salesRepUser
              )
            : ""}
        </div>
      </div>
      <div className="flex flex-row justify-between gap-2">
        <div className="flex flex-row gap-2 items-center">
          {order && (
            <Badge
              style={{
                backgroundColor: order.status.color,
                color: getContrast(order.status.color),
              }}
              size="md"
              className="pointer-events-none"
            >
              {order.status.name}
            </Badge>
          )}
          {productions && (
            <div className="flex flex-row gap-2 items-center flex-wrap">
              -
              {productions.map(pr => (
                <div
                  onClick={() => {
                    checkAuth("order_release") &&
                      navigate(`/production/detail/${pr.id}`);
                  }}
                  className={`${
                    checkAuth("order_release") && "cursor-pointer"
                  } flex flex-row gap-2 items-center`}
                  key={pr.id}
                >
                  {pr.product.image && (
                    <div className="ring-1 ring-dark rounded-full p-1 bg-white">
                      <img src={pr.product.image} className="w-4" />
                    </div>
                  )}
                  <div className="capitalize">
                    {pr.productionStatus.name}-{pr.team.name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex flex-row items-center gap-2">
          <TextInput
            sizing="sm"
            type="date"
            addon="due"
            {...register("due")}
            placeholder="enter due date"
          />
          <TextInput
            sizing="sm"
            addon="ref"
            {...register("orderRef")}
            placeholder="your order ref"
          />
        </div>
      </div>
      <Textarea
        {...register("description")}
        className="text-sm"
        placeholder="remakrs, special requests"
      />
    </div>
  );
};

const OrderActions = ({
  defaultValues,
  saving,
  saveForm,
  process,
  type,
  setPage,
}: {
  defaultValues: order;
  saving: boolean;
  saveForm: () => Promise<void>;
  process?: ProcessType;
  type: string;
  setPage: (page: string) => void;
}) => {
  const { control, setValue, reset, getValues } = useFormContext();

  const showOptionals = useWatch({ name: "showOptionals", control });
  const toggleOptionals = () => {
    setValue("showOptionals", !showOptionals);
  };

  const showCalcs = useWatch({ name: "showCalcs", control });
  const toggleCalcs = () => {
    setValue("showCalcs", !showCalcs);
  };

  const canSeePrice = ["quote", "order"].includes(type as string);

  const showPrice = useWatch({
    name: "showPrice",
    control,
    defaultValue: false,
  });

  const togglePrice = () => {
    setValue("showPrice", !showPrice);
  };

  const showNotes = useWatch({
    name: "showNotes",
    control,
    defaultValue: false,
  });
  const toggleNotes = () => {
    setValue("showNotes", !showNotes);
  };

  const fileRef = useRef<HTMLInputElement>(null);

  const upload = () => {
    fileRef.current?.click();
  };

  const save = () => {
    const orderData = getValues();
    const json = JSON.stringify(orderData);
    const data = new Blob([json], { type: "text/plain" });
    const textFile = window.URL.createObjectURL(data);

    let link = document.createElement("a");
    link.setAttribute("download", `${orderData.name}`);
    link.setAttribute("target", "_blank");
    link.href = textFile;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const isQuote = type === "quote";
  const goToQuoteParams = () => {
    setPage("quoteParams");
  };

  const { user } = useAuthStore();

  return (
    <div className="flex flex-row justify-between gap-2 mb-2 border-b-[1px] border-plum py-2">
      <div className="flex flex-row gap-2">
        <div
          className="flex flex-row gap-2 items-center cursor-pointer hover:text-grass"
          onClick={toggleOptionals}
        >
          {showOptionals ? (
            <EyeIcon className="w-4" />
          ) : (
            <EyeSlashIcon className="w-4" />
          )}
          extra options
        </div>
        {user?.auth.name !== "dealer" && (
          <div
            className="flex flex-row gap-2 items-center cursor-pointer hover:text-grass"
            onClick={toggleCalcs}
          >
            {showCalcs ? (
              <EyeIcon className="w-4" />
            ) : (
              <EyeSlashIcon className="w-4" />
            )}
            calcs
          </div>
        )}

        <div
          className="flex flex-row gap-2 items-center cursor-pointer hover:text-grass"
          onClick={toggleNotes}
        >
          {showNotes ? (
            <EyeIcon className="w-4" />
          ) : (
            <EyeSlashIcon className="w-4" />
          )}
          notes
        </div>
        {canSeePrice && (
          <div
            className="flex flex-row gap-2 items-center cursor-pointer hover:text-grass"
            onClick={togglePrice}
          >
            {showPrice ? (
              <EyeIcon className="w-4" />
            ) : (
              <EyeSlashIcon className="w-4" />
            )}
            price
          </div>
        )}
        {isQuote && (
          <div
            className={`flex flex-row gap-2 items-center cursor-pointer hover:text-grass`}
            onClick={goToQuoteParams}
          >
            <AdjustmentsVerticalIcon className="w-4" />
            quoteParams
          </div>
        )}
      </div>
      <div className="flex flex-row gap-2">
        {saving && <Spinner size="xs" color="purple" />}
        <div
          className={`flex flex-row gap-2 items-center cursor-pointer hover:text-grass`}
          onClick={saveForm}
        >
          <CloudArrowUpIcon className="w-4" />
          save
        </div>
        <div
          className="flex flex-row gap-2 items-center cursor-pointer hover:text-grass"
          onClick={save}
        >
          <ArrowUpTrayIcon className="w-4" />
          export
        </div>
        <div
          className="flex flex-row gap-2 items-center cursor-pointer hover:text-grass"
          onClick={upload}
        >
          <ArrowDownTrayIcon className="w-4" />
          import
        </div>
        <div
          className="flex flex-row gap-2 items-center cursor-pointer hover:text-grass"
          onClick={() => {
            const proceed = confirm("delete all data and reset form?");
            if (!proceed) {
              return;
            }
            reset(defaultValues);
          }}
        >
          <TrashIcon className="w-4" />
          clear all
        </div>
        <input
          type="file"
          accept=".txt"
          className="hidden"
          ref={fileRef}
          onChange={e => {
            if (e.target.files && e.target.files[0]) {
              let reader = new FileReader();
              reader.onload = e => {
                const order = e.target?.result;
                if (order) {
                  const proceed = confirm(
                    "replace current work with the uploaded file?"
                  );
                  if (proceed) {
                    const parsedOrder = JSON.parse(order as string);
                    console.log(parsedOrder);
                    reset(parsedOrder);
                  }
                }
              };
              reader.readAsText(e.target.files[0]);
            }
          }}
        />
      </div>
    </div>
  );
};

import { useOrderStore } from "../../../../../../store/orderStore";
import { useQuoteStore } from "../../../../../../store/quoteStore";
import isNullish from "../../../../../../utils/isNullish";
import { calc, item, opening, option, order } from "../../types";
import { create, all } from "mathjs";
import findPriceFromTable from "./findPriceFromTable";
import useScopeConcat from "../ordering/options/scopeConcat";
import { MaterialsType } from "../../../../materials/types";

interface props {
  isOpening: boolean;
  item?: item;
  opening?: opening;
  productSetId: number;
  sqm: number;
  isRetail: boolean;
  userId?: number;
  itemSalesParams?: item["salesParams"] | opening["salesParams"];
  salesParams?: order["salesParams"];
}

interface tablePriceBreakDown {
  formula: string;
  selected: string;
  price: number;
}

interface materialCostBreakDown {
  material: MaterialsType;
  qty: number;
  price: number;
  total: number;
  noPrice?: boolean;
}

interface productionBreakDown {
  preset: string;
  name: string;
  labour: string;
  total: number;
}

interface installBreakDown {
  formula: string;
  qty: number;
  formulaType: string;
  baseCost: number;
  additionalCharges: {
    name: string;
    formula: string;
    price: number;
  }[];
  total: number;
}

export interface breakdownData {
  formula?: string;
  subTotal: number;
  scopes: { [key: string]: any };
  materialCostBreakDowns: materialCostBreakDown[];
  productionBreakDowns: productionBreakDown[];
  retailMargin?: number;
  installBreakDown?: installBreakDown;
  wholesaleDiscountRate: number;
  retailDiscount?: {
    type: "rate" | "amount";
    value: number;
    deductedValue: number;
    perItemDiscount?: number;
  };
  tablePriceBreakDown?: tablePriceBreakDown;
}

export default function useGetPrice() {
  const { materials, presets, productSets } = useOrderStore();
  const { quoteParams, pricingTables } = useQuoteStore();

  const defaultMargin =
    quoteParams.find(param => param.name == "margin" && !param.productSetId)
      ?.value || 30;

  const math = create(all);

  math.SymbolNode.onUndefinedSymbol = e => {
    // console.log(e);
    return 0;
  };

  const scopeConcat = useScopeConcat();

  return ({
    isOpening,
    item,
    opening,
    sqm,
    productSetId,
    isRetail,
    userId,
    itemSalesParams,
    salesParams,
  }: props) => {
    try {
      const productSet = productSets.find(ps => ps.id == productSetId);
      const productMargin = quoteParams.find(
        param => param.name == "margin" && param.productSetId == productSetId
      )?.value;
      const productBaseInstallCost = quoteParams.find(
        param => param.name == "install" && param.productSetId == productSetId
      )?.value;

      const myProductSalesParam = salesParams?.productParams?.find(
        p => p.productSetId == productSetId
      );

      const defaultWholesaleMargin = isNullish(productMargin?.wholesale)
        ? defaultMargin
        : productMargin.wholesale || 0;

      const wholesaleMargin = isRetail
        ? defaultWholesaleMargin
        : !isNullish(myProductSalesParam?.margin)
        ? myProductSalesParam?.margin
        : defaultWholesaleMargin;

      let sqmPrice = quoteParams.find(
        param =>
          param.name == "sqmPrice" && param.productSetId == opening?.product
      )?.value;

      sqmPrice = Number(
        isRetail ? sqmPrice?.retail || 0 : sqmPrice?.wholesale || 0
      );

      if (!isRetail) {
        const dealerSqmPrice = quoteParams.find(
          p =>
            p.name == "sqmPrice" &&
            p.productSetId == opening?.product &&
            p.userId == userId
        );

        if (dealerSqmPrice) {
          sqmPrice = Number(dealerSqmPrice.value);
        }
      }

      const wholesaleDiscountRate = Number(
        !isRetail
          ? quoteParams.find(
              p =>
                p.name == "discount" &&
                p.productSetId == opening?.product &&
                p.userId == userId
            )?.value || 0
          : 0
      );

      let retailProductDiscount = {
        value: 0,
        type: "rate",
      };

      const retailPerItemDiscount = Number(itemSalesParams?.discount || 0);

      const retailMargin = isNullish(myProductSalesParam?.margin)
        ? Number(productMargin?.retail)
        : Number(myProductSalesParam?.margin);

      if (isRetail) {
        if (myProductSalesParam?.sqm) {
          sqmPrice = Number(myProductSalesParam?.sqm);
        }
        if (myProductSalesParam?.discount) {
          retailProductDiscount = myProductSalesParam?.discount;
        }
      }

      const materialCostBreakDowns: materialCostBreakDown[] = [];

      const materialCost =
        item?.materials?.reduce((acc, cur) => {
          const material = materials.find(mat => mat.id == cur.id);
          if (!material) {
            return acc;
          }

          const price =
            material.prices.find(p => p.unit == cur.unit)?.price || 0;
          const materialPrice = price * cur.qty;

          materialCostBreakDowns.push({
            material,
            qty: cur.qty,
            price: price,
            total: materialPrice,
            noPrice: cur.noPrice,
          });

          if (cur.noPrice) {
            return acc;
          }

          return acc + materialPrice;
        }, 0) || 1;

      const formula = quoteParams.find(
        param => param.name == "formula" && param.productSetId == productSetId
      )?.value;

      const options: option[] = item?.options || opening?.options || [];
      const calcs: calc[] = item?.calcs || [];

      const optionNamePopulatedCalcs = calcs.map(c => ({
        ...c,
        optionName: options.find(o => o.id == c.optionId)?.name || "",
      }));

      const calcScopes = optionNamePopulatedCalcs.reduce<any>(
        (prv, cur) => {
          return {
            ...prv,
            [cur.name.replaceAll(" ", "")]: cur.size,
            [`${cur.optionName}${cur.name}`.replaceAll(" ", "")]: cur.size,
          };
        },
        {
          W: item?.width,
          H: item?.height,
        }
      );

      let scopes = options.reduce<any>(
        (prv, cur) => {
          if (cur.noCalc) return prv;

          const scopeName = cur.name.replaceAll(" ", "");
          let appended = {
            ...prv,
          };

          appended = scopeConcat({
            prvScopes: appended,
            scopeName,
            option: cur,
          });

          return appended;
        },
        {
          ...calcScopes,
          sqm,
          material: materialCost,
          margin: wholesaleMargin,
          isRetail,
          sqmPrice,
        }
      );

      const myTable = pricingTables
        .filter(
          table =>
            table.productSetId == productSetId &&
            (!table.condition || math.evaluate(table.condition, scopes))
        )
        .sort((a, b) => Number(!!b.condition) - Number(!!a.condition))[0];

      const width = isOpening
        ? opening?.options?.find(o => o.name == "W")?.value
        : item?.width;
      const height = isOpening
        ? opening?.options?.find(o => o.name == "H")?.value
        : item?.height;

      const tablePrice = myTable
        ? findPriceFromTable({
            width,
            height,
            table: myTable,
          })
        : 0;

      const tablePriceBreakDown: tablePriceBreakDown | undefined = myTable
        ? {
            formula: myTable.condition!!,
            selected: myTable.name,
            price: tablePrice,
          }
        : undefined;

      scopes = {
        ...scopes,
        tablePrice,
      };

      const presetOptions =
        (options &&
          options.filter(o => o.source == "deductionPreset" && !o.noCalc)) ||
        [];

      const productionBreakDowns: productionBreakDown[] = [];

      const production = presetOptions.reduce<number>((prev, cur) => {
        const preset = presets.find(p => p.id == cur.value);
        const name = cur.name;
        if (!preset || !preset.labour) {
          return prev;
        }
        let presetScopes = { ...scopes, name };

        for (const prop in scopes) {
          if (prop.includes(name)) {
            presetScopes = {
              ...presetScopes,
              [prop.replace(name, "")]: scopes[prop],
            };
          }
        }

        const total = prev + math.evaluate(preset.labour, presetScopes);

        productionBreakDowns.push({
          preset: name,
          name: cur.displayValue || cur.value,
          labour: preset.labour,
          total,
        });

        return total;
      }, 0);

      const supOnly = Number(itemSalesParams?.supplyOnly);

      scopes = {
        ...scopes,
        production,
        supplyOnly: Boolean(supOnly),
      };

      const productQty = math.evaluate(productSet?.qtyCalc || "1", scopes);

      // INSTALLATION COST CALCULATION
      const calcInstallation = () => {
        if (supOnly) {
          return { cost: 0, breakdown: undefined };
        }

        const formula =
          myProductSalesParam?.baseInstall || productBaseInstallCost || "1";

        const baseCost = math.evaluate(formula, scopes);

        let cost = baseCost * productQty;

        let breakdown: installBreakDown = {
          formula,
          formulaType: myProductSalesParam?.baseInstall ? "custom" : "default",
          qty: productQty,
          baseCost: cost,
          additionalCharges: [],
          total: 0,
        };

        if (itemSalesParams?.installParams) {
          const installParamsCost =
            itemSalesParams.installParams.reduce<number>((prev, cur) => {
              if (Number(cur.value)) {
                const price = math.evaluate(cur.price || "1", {
                  ...scopes,
                  baseCost,
                });

                breakdown.additionalCharges.push({
                  name: cur.name,
                  formula: cur.price,
                  price,
                });

                return prev + price;
              }
              return prev;
            }, 0);

          cost += installParamsCost;
        }

        return { cost, breakdown: { ...breakdown, total: cost } };
      };

      // FINAL COST CALCULATION FROM HERE

      // IF FORMULA DEFINED
      if (formula) {
        let cost = math.evaluate(formula, scopes);

        const subTotal = cost;

        if (isRetail) {
          if (retailMargin) {
            cost = cost / ((100 - retailMargin) / 100);
          }
        }

        let discount = wholesaleDiscountRate
          ? cost - cost * (wholesaleDiscountRate / 100)
          : 0;

        if (isRetail) {
          discount =
            retailPerItemDiscount +
            (retailProductDiscount.type == "rate"
              ? (cost * Number(retailProductDiscount.value)) / 100
              : Number(retailProductDiscount.value));
        }

        const installCostData = calcInstallation();

        const breakdownData: breakdownData = {
          subTotal,
          formula,
          scopes,
          materialCostBreakDowns,
          productionBreakDowns,
          retailMargin,
          installBreakDown: installCostData.breakdown,
          wholesaleDiscountRate,
          tablePriceBreakDown,
          retailDiscount: isRetail
            ? {
                type: retailProductDiscount.type == "rate" ? "rate" : "amount",
                value: retailProductDiscount.value,
                deductedValue: discount,
                perItemDiscount: retailPerItemDiscount,
              }
            : undefined,
        };

        return {
          price: Math.ceil(cost - discount),
          install: isRetail ? installCostData?.cost || 0 : undefined,
          breakdownData,
        };
      }

      // IF NO FORMULA AND OPENING PRODUCT
      if (isOpening && opening) {
        let cost = sqm * sqmPrice;

        const subTotal = cost;

        let discount = wholesaleDiscountRate
          ? cost - cost * (wholesaleDiscountRate / 100)
          : 0;

        if (isRetail) {
          discount =
            retailPerItemDiscount +
            (retailProductDiscount.type == "rate"
              ? (cost * Number(retailProductDiscount.value)) / 100
              : Number(retailProductDiscount.value));
        }

        const installCostData = calcInstallation();

        const breakdownData: breakdownData = {
          subTotal,
          formula,
          scopes,
          materialCostBreakDowns,
          productionBreakDowns,
          retailMargin,
          installBreakDown: installCostData.breakdown,
          wholesaleDiscountRate,
          tablePriceBreakDown,
          retailDiscount: isRetail
            ? {
                type: retailProductDiscount.type == "rate" ? "rate" : "amount",
                value: retailProductDiscount.value,
                deductedValue: discount,
                perItemDiscount: retailPerItemDiscount,
              }
            : undefined,
        };

        return {
          price: Math.ceil(cost - discount),
          install: isRetail ? installCostData.cost || 0 : undefined,
          breakdownData,
        };
      }

      // IF NO FORMULA AND ITEM
      if (!isOpening && item) {
        let cost =
          (materialCost + production) / ((100 - wholesaleMargin) / 100);

        const subTotal = cost;

        if (isRetail) {
          cost = cost / ((100 - retailMargin) / 100);
        }
        let discount = wholesaleDiscountRate
          ? cost - cost * (wholesaleDiscountRate / 100)
          : 0;

        if (isRetail) {
          discount =
            retailPerItemDiscount +
            (retailProductDiscount.type == "rate"
              ? (cost * Number(retailProductDiscount.value)) / 100
              : Number(retailProductDiscount.value));
        }

        const installCostData = calcInstallation();

        const breakdownData: breakdownData = {
          subTotal,
          formula,
          scopes,
          materialCostBreakDowns,
          productionBreakDowns,
          retailMargin,
          installBreakDown: installCostData.breakdown,
          wholesaleDiscountRate,
          tablePriceBreakDown,
          retailDiscount: isRetail
            ? {
                type: retailProductDiscount.type == "rate" ? "rate" : "amount",
                value: retailProductDiscount.value,
                deductedValue: discount,
                perItemDiscount: retailPerItemDiscount,
              }
            : undefined,
        };

        return {
          price: Math.ceil(cost - discount),
          install: isRetail ? installCostData.cost || 0 : undefined,
          breakdownData,
        };
      }
    } catch (error) {
      console.log(error);
      return {
        price: 0,
        install: 0,
      };
    }
  };
}

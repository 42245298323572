import { PrinterIcon } from "@heroicons/react/24/solid";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { OrderType } from "../../../types";
import dayjs from "dayjs";
import { curtainLabel, filteredLocation } from "./types";
import CurtainLabel from "./curtainLabel";
import getCurtainLabels from "./getCurtainLabels";

interface props {
  fullNum: string;
  customer?: string;
  pageMarginStyle: string;
  dbOrder?: OrderType;
  filteredLocations?: filteredLocation[];
}

export default function CurtainLabels({
  fullNum,
  customer,
  dbOrder,
  filteredLocations,
}: props) {
  const labelPageRef = useRef(null);
  const printLabels = useReactToPrint({
    content: () => labelPageRef.current,
    documentTitle: customer || fullNum || "no-name",
  });

  const labels: curtainLabel[] = getCurtainLabels(filteredLocations || []);

  let labelRows: curtainLabel[][] = [];

  const chunkSize = 3;

  for (let i = 0; i < labels.length; i += chunkSize) {
    const chunk = labels.slice(i, i + chunkSize);
    labelRows.push(chunk);
  }

  return (
    <div className="w-[277mm]">
      <div className="flex flex-row justify-end items-center">
        <div
          className="flex flex-row gap-2 items-center cursor-pointer hover:text-plum"
          onClick={printLabels}
        >
          Print
          <PrinterIcon className="w-4" />
        </div>
      </div>
      <div ref={labelPageRef} className="flex flex-col gap-2">
        <h1 className="print:hidden mb-5 text-center dark:text-black">
          Curtain Labels
        </h1>
        <div className="print:hidden flex flex-row justify-between">
          <h3 className="dark:text-black">
            Due: {dbOrder?.due ? dayjs(dbOrder.due).format("DD/MM/YY") : "N/A"}
          </h3>
          <table className="border-[1px] border-gray-400">
            <tbody>
              <tr className="border-b-[1px] border-gray-400">
                <th className="border-r-[1px] border-gray-400 px-1">Job ID</th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  {fullNum}
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  Customer Ref
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  {dbOrder?.orderRef || "N/A"}
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  Dealer ID
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  {dbOrder?.createdBy}
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  Dealer Name
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">
                  {dbOrder?.user.firstName} {dbOrder?.user.sirName}
                </th>
              </tr>
              <tr>
                <th className="border-r-[1px] border-gray-400 px-1">
                  Client Name
                </th>
                <th colSpan={3} className="border-r-[1px] border-gray-400 px-1">
                  {dbOrder?.process?.name}
                </th>
                <th className="border-r-[1px] border-gray-400 px-1">Address</th>
                <th colSpan={3} className="border-r-[1px] border-gray-400 px-1">
                  {dbOrder?.process?.address}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <section className="flex flex-col print:mx-[10mm]">
          {labelRows.map((labelRow, i) => (
            <div
              key={i}
              className={`
                border-l-[1px] border-t-[1px] border-black flex flex-row h-[100mm]
                ${i == labelRows.length - 1 && "border-b-[1px]"}
                ${i % 2 == 1 && "print:border-b-[1px]"}
                ${i % 2 == 0 && "print:mt-[5mm]"}
                ${i !== 0 && i % 2 == 0 && "print:break-before-page"}`}
            >
              {labelRow.map((label, j) => (
                <CurtainLabel
                  label={label}
                  customer={customer || dbOrder?.process?.name}
                  orderNumber={fullNum}
                  key={j}
                />
              ))}
              {labelRow.length < 3 &&
                Array(3 - labelRow.length)
                  .fill(0)
                  .map((_, i) => (
                    <div
                      key={i}
                      className="w-1/3 border-r-[1px] border-black p-2 relative"
                    />
                  ))}
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}

import {
  subscription,
  usePub,
  useSubs,
} from "../../../../../../../../utils/pubsub/pubsub";
import { option } from "../../../../types";
import { useFormContext } from "react-hook-form";
import {
  orderEvent,
  OPTION_UPDATE_ARGS,
  NO_CALC_UPDATE_ARGS,
} from "../../../../../../../../utils/pubsub/orderEventArgs";
import { optionValues } from "../../../../../types";
import { useEffectOnce } from "usehooks-ts";

interface optionsSEProps {
  optionsCoord: string;
  optionCoord: string;
  openingId: string;
  itemId: string;
  optionId: number;
  locked?: boolean;
}

const OptionsSideEffects = ({
  optionsCoord,
  optionCoord,
  openingId,
  itemId,
  optionId,
  locked,
}: optionsSEProps) => {
  const { getValues, setValue } = useFormContext();

  const publish = usePub<NO_CALC_UPDATE_ARGS>();

  const update = () => {
    if (locked) {
      return;
    }
    const optionParents: optionValues[] = getValues(`${optionCoord}.parent`);
    const options: option[] = getValues(optionsCoord);

    const poCheck =
      options &&
      options
        .filter(o => optionParents?.some(p => p.option == o.id))
        .every(
          p =>
            optionParents?.find(po =>
              po.values.map(v => v.toString()).includes(p.value.toString())
            ) && !p.noCalc
        );

    if (poCheck == !getValues(`${optionCoord}.noCalc`)) {
      return;
    }

    setValue(`${optionCoord}.noCalc`, !poCheck);
    publish(orderEvent.NO_CALC_UPDATE, {
      orderEvent: orderEvent.NO_CALC_UPDATE,
      openingId,
      itemId,
      optionId,
    });
  };

  const updateBySup = (args: OPTION_UPDATE_ARGS | NO_CALC_UPDATE_ARGS) => {
    if (args.optionId == optionId) return;
    if (args.openingId == openingId && args.itemId == itemId) {
      update();
    }
  };

  const subscriptions: subscription[] = [
    {
      event: orderEvent.OPTION_UPDATE,
      callback: args => updateBySup(args as OPTION_UPDATE_ARGS),
    },
    {
      event: orderEvent.NO_CALC_UPDATE,
      callback: args => updateBySup(args as NO_CALC_UPDATE_ARGS),
    },
  ];

  useSubs(subscriptions, [optionCoord, openingId, itemId, locked]);

  useEffectOnce(() => {
    update();
  });

  return null;
};

export default OptionsSideEffects;

import { useMutation, useQuery } from "@apollo/client";
import {
  CheckIcon,
  HomeIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  Badge,
  Breadcrumb,
  Button,
  Label,
  TextInput,
  Textarea,
} from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GET_PRODUCT_SET_BY_ID,
  UPDATE_PRODUCT_SET_BY_PK,
} from "../../../workorder/gqls";
import { useState } from "react";
import {
  useFieldArray,
  useForm,
  useWatch,
  FormProvider,
  useFormContext,
  UseFieldArrayRemove,
  useFormState,
} from "react-hook-form";
import { formula } from "../../../workorder/types";
import ProductSetOptions from "./productSetOptions";
import ProductSetReleaseSetting from "./productSetReleaseSetting";
import { useOrderStorePopulate } from "../../../../../store/orderStore";
import ProductSetSizeLimitSetting from "./productSetSizeLimitSetting";
import ProductSetWarnings from "./productSetWarnings/productSetWarnings";

export default function ProductSetEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const id = Number(params.id);

  const [update] = useMutation(UPDATE_PRODUCT_SET_BY_PK);

  const defaultValues: {
    name: string;
    typeId: number;
    calcs: formula[];
    hideItems?: boolean;
    qtyCalc?: string;
    summaryCalc?: string;
    summaryCalcUnit?: string;
  } = {
    name: "",
    typeId: 0,
    calcs: [],
    hideItems: false,
    qtyCalc: undefined,
    summaryCalc: undefined,
    summaryCalcUnit: undefined,
  };

  const methods = useForm({
    defaultValues,
  });

  const { register, reset, handleSubmit, control } = methods;
  const [editing, setEditing] = useState("");

  const { data } = useQuery(GET_PRODUCT_SET_BY_ID, {
    variables: {
      id,
    },
    onCompleted(data) {
      const productSet = data?.productSets_by_pk;
      if (productSet) {
        const defaultValues = {
          name: productSet?.name,
          typeId: productSet?.product.id,
          calcs: productSet?.calcs,
          hideItems: productSet?.hideItems,
          qtyCalc: productSet?.qtyCalc,
          summaryCalc: productSet?.summaryCalc,
          summaryCalcUnit: productSet?.summaryCalcUnit,
        };

        reset(defaultValues);
      }
    },
  });

  const productSet = data?.productSets_by_pk;

  const cancel = () => {
    reset();
    setEditing("");
  };

  const modify = handleSubmit(data => {
    update({
      variables: {
        id: id,
        set: {
          ...data,
        },
      },
      onCompleted() {
        reset(data);
        setEditing("");
      },
    });
  });

  const calcs = useWatch({
    name: "calcs",
    control,
  });

  const { append, remove } = useFieldArray({
    name: "calcs",
    control,
  });

  const defaultCalc = {
    name: "",
    formula: "",
  };

  const [newCalc, setNewCalc] = useState(defaultCalc);
  const [adding, setAdding] = useState(false);

  const addCalc = () => {
    const { name, formula } = newCalc;
    if (`${name}${formula}`.trim() == "") {
      return;
    }
    append(newCalc);
    modify();
    setNewCalc(defaultCalc);
    setAdding(false);
  };

  const cancelAdding = () => {
    setNewCalc(defaultCalc);
    setAdding(false);
  };

  const loading = useOrderStorePopulate();

  if (loading) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <div className="mb-1 flex-grow mx-auto">
        <section className="shadow-md bg-white dark:bg-gray-800 rounded-md p-4 space-y-4">
          <nav className="w-full">
            <Breadcrumb className="w-full">
              <Breadcrumb.Item
                className="cursor-pointer"
                onClick={() => {
                  navigate("../");
                }}
              >
                <HomeIcon className="w-5 mr-2" />
                Settings
              </Breadcrumb.Item>

              <Breadcrumb.Item
                className="cursor-pointer"
                onClick={() => {
                  navigate("/settings/order");
                }}
              >
                Order
              </Breadcrumb.Item>
              <Breadcrumb.Item>ProductSet</Breadcrumb.Item>
            </Breadcrumb>
          </nav>
          {productSet && (
            <main className="gap-2 flex flex-col">
              {/* Name */}
              <div className="flex flex-row items-center gap-2">
                {editing == "name" ? (
                  <>
                    <input
                      className="border-none p-0 text-xl font-medium"
                      {...register("name")}
                      autoFocus
                    />
                    <CheckIcon
                      className="w-4 text-grass cursor-pointer"
                      onClick={modify}
                    />
                    <XMarkIcon
                      className="w-4 text-red-500 cursor-pointer"
                      onClick={cancel}
                    />
                  </>
                ) : (
                  <>
                    <h2>{productSet.name}</h2>
                    <PencilIcon
                      className="w-4 text-grass cursor-pointer"
                      onClick={() => {
                        setEditing("name");
                      }}
                    />
                  </>
                )}
              </div>
              {/* Product Type */}
              <div className="flex flex-row gap-2">
                <Badge color="purple">Product: {productSet.product.name}</Badge>
                <Badge color="purple">
                  Type: {productSet.hideItems ? "Opening Based" : "Item Based"}
                </Badge>
              </div>
              <QtyCalcFunction modify={modify} />
              <SummaryCalcFunction modify={modify} />
              {/* Calcs */}
              {productSet.hideItems && (
                <div className="flex flex-col gap-2">
                  {/* Calc Heading, Add Icon */}
                  <div className="flex flex-row justify-between items-center">
                    <h3>Calc Functions</h3>
                    {adding ? (
                      <MinusCircleIcon
                        className="w-5 text-red-500 cursor-pointer"
                        onClick={() => {
                          setAdding(false);
                        }}
                      />
                    ) : (
                      <PlusCircleIcon
                        className="w-5 text-grass cursor-pointer"
                        onClick={() => {
                          setAdding(true);
                        }}
                      />
                    )}
                  </div>
                  {adding && (
                    <div className="p-2 border-[1px] rounded-md flex flex-col gap-2">
                      <Label value="Add New Calc" />
                      <TextInput
                        value={newCalc.name}
                        sizing="sm"
                        addon="Name"
                        onChange={e => {
                          setNewCalc({
                            ...newCalc,
                            name: e.target.value,
                          });
                        }}
                      />
                      <Textarea
                        value={newCalc.formula}
                        placeholder="formula"
                        className="text-sm"
                        onChange={e => {
                          setNewCalc({
                            ...newCalc,
                            formula: e.target.value,
                          });
                        }}
                      />
                      <div className="flex flex-row justify-end gap-2">
                        <Button size="xs" color="purple" onClick={cancelAdding}>
                          cancel
                        </Button>
                        <Button
                          size="xs"
                          outline
                          gradientDuoTone="greenToBlue"
                          onClick={addCalc}
                        >
                          add calc
                        </Button>
                      </div>
                    </div>
                  )}
                  <Label>Registered Functions</Label>
                  <div className="flex flex-col gap-2">
                    {calcs?.map((calc, i) => (
                      <Calc
                        key={i}
                        calc={calc}
                        index={i}
                        modify={modify}
                        remove={remove}
                      />
                    ))}
                  </div>
                  <hr />
                </div>
              )}
              <ProductSetReleaseSetting productSetId={id} />
              <ProductSetSizeLimitSetting productSetId={id} />
              <ProductSetWarnings productSetId={id} />
              <ProductSetOptions productSetId={id} />
            </main>
          )}
        </section>
      </div>
    </FormProvider>
  );
}

const QtyCalcFunction = ({ modify }: { modify: () => void }) => {
  const { register, control, reset } = useFormContext();
  const { isDirty } = useFormState({
    control,
    name: "qtyCalc",
    exact: true,
  });

  const cancel = () => {
    reset();
  };

  const update = () => {
    modify();
  };
  return (
    <>
      <h3>Qty Calc Function</h3>
      <div className="flex flex-row gap-2 items-center">
        <TextInput
          sizing="sm"
          className="flex-1"
          addon="f(x)"
          {...register("qtyCalc")}
        />
        {isDirty && (
          <>
            <div className="flex flex-row items-center gap-1">
              <Button size="xs" color="purple" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="xs"
                outline
                gradientDuoTone={"purpleToBlue"}
                onClick={update}
              >
                update
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const SummaryCalcFunction = ({ modify }: { modify: () => void }) => {
  const { register, control, reset } = useFormContext();
  const { isDirty } = useFormState({
    control,
    name: "summaryCalc",
    exact: true,
  });

  const { isDirty: isUnitDirty } = useFormState({
    control,
    name: "summaryCalcUnit",
    exact: true,
  });

  const cancel = () => {
    reset();
  };

  const update = () => {
    modify();
  };
  return (
    <>
      <h3>Summary Calc Function</h3>
      <div className="flex flex-row gap-2 items-center">
        <TextInput
          sizing="sm"
          className="flex-1"
          addon="f(x)"
          {...register("summaryCalc")}
        />
        <TextInput
          sizing="sm"
          className="w-32"
          addon="unit"
          {...register("summaryCalcUnit")}
        />
        {(isDirty || isUnitDirty) && (
          <>
            <div className="flex flex-row items-center gap-1">
              <Button size="xs" color="purple" onClick={cancel}>
                cancel
              </Button>
              <Button
                size="xs"
                outline
                gradientDuoTone={"purpleToBlue"}
                onClick={update}
              >
                update
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

interface calcProps {
  calc: formula;
  index: number;
  modify: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  remove: UseFieldArrayRemove;
}

const Calc = ({ calc, index, modify, remove }: calcProps) => {
  const [editing, setEditing] = useState(false);
  const { register, reset } = useFormContext();
  const myCoord = `calcs.${index}`;

  const cancel = () => {
    reset();
    setEditing(false);
  };

  const update = () => {
    modify();
    setEditing(false);
  };

  const _delete = () => {
    const proceed = confirm("delete calculation?");
    if (proceed) {
      remove(index);
      modify();
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between">
        <h4 className="font-semibold">{calc.name}</h4>
        {!editing && (
          <PencilIcon
            className="w-4 cursor-pointer text-grass"
            onClick={() => {
              setEditing(true);
            }}
          />
        )}
      </div>
      {editing && (
        <div className="flex flex-col gap-2">
          <Textarea
            className="text-sm"
            {...register(`${myCoord}.formula`)}
            rows={10}
          />
          <div className="flex flex-row justify-end gap-2">
            <TrashIcon
              className="text-red-500 w-5 cursor-pointer"
              onClick={_delete}
            />
            <Button size="xs" color="purple" onClick={cancel}>
              cancel
            </Button>
            <Button
              size="xs"
              outline
              gradientDuoTone="greenToBlue"
              onClick={update}
            >
              update
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

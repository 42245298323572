import { create } from "zustand";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { RecordFilter } from "../routes/protected/records/types";
import { queryParams } from "../routes/protected/shared/types";
import dayjs from "dayjs";
import { User, useAuthStore } from "./authStore";
import checkAuth from "../utils/checkAuth";
import { GET_TEAMS } from "../routes/protected/settings/teams/teamList";
import { TeamType } from "../routes/protected/settings/teams/types";
import {
  DeductionPresetType,
  DeductionType,
  ProductSetType,
  optionProps,
  orderStatusType,
  orderSummaryColumn,
} from "../routes/protected/workorder/types";
import {
  GET_DEDUCTIONS_NO_IMG,
  GET_DEDUCTION_PRESETS,
  GET_ORDER_STATUS,
  GET_ORDER_SUMMARY_COLUMN,
  GET_ORDER_TYPES,
  GET_PRODUCT_OPTIONS,
  GET_PRODUCT_SETS_MIN,
} from "../routes/protected/workorder/gqls";
import { MaterialsType } from "../routes/protected/materials/types";
import { GET_MATERIALS_MIN } from "../routes/protected/materials/gqls";
import { unit } from "../routes/protected/shared/units/types";
import { GET_UNITS } from "../routes/protected/shared/units/gqls";
import { sort } from "fast-sort";

type Store = {
  filters: RecordFilter[];
  setFilters: (filters: RecordFilter[]) => void;
  cursor: string;
  setCursor: (str: string) => void;
  deductions: DeductionType[];
  getDeductionsByIds: (ids: number[]) => DeductionType[];
  setDeductions: (deductions: DeductionType[]) => void;
  addDeductions: (deductions: DeductionType[]) => void;
  productSetOptions: optionProps[];
  getProductSetOptionById: (id: number) => optionProps | undefined;
  setProductSetOptions: (productSetOptions: optionProps[]) => void;
  materials: MaterialsType[];
  setMaterials: (materials: MaterialsType[]) => void;
  addMaterials: (materials: MaterialsType[]) => void;
  presets: DeductionPresetType[];
  setPresets: (presets: DeductionPresetType[]) => void;
  productSets: Omit<ProductSetType, "productSetOptions">[];
  setProductSets: (productSets: ProductSetType[]) => void;
  orderSummaryColumn: orderSummaryColumn[];
  setOrderSummaryColumn: (orderSummaryColumn: orderSummaryColumn[]) => void;
  orderTypes: orderStatusType[];
  setOrderTypes: (orderTypes: orderStatusType[]) => void;
  units: unit[];
  setUnits: (units: unit[]) => void;
};

export const useOrderStore = create<Store>((set, get) => ({
  filters: [],
  setFilters: filters => {
    if (filters.length == 0) {
      localStorage.removeItem("orderFilters");
    }
    localStorage.setItem("orderFilters", JSON.stringify(filters));
    return set(state => ({
      ...state,
      filters,
    }));
  },
  cursor: new Date().toISOString(),
  setCursor(cursor: string) {
    return set(state => ({
      ...state,
      cursor,
    }));
  },
  deductions: [],
  setDeductions(deductions) {
    return set(state => ({
      ...state,
      deductions,
    }));
  },
  addDeductions(deductions) {
    return set(state => ({
      ...state,
      deductions: state.deductions.concat(deductions),
    }));
  },
  getDeductionsByIds(ids) {
    return get().deductions.filter(d => ids.includes(d.id));
  },
  productSetOptions: [],
  getProductSetOptionById(id) {
    return get().productSetOptions.find(o => id == o.id);
  },
  setProductSetOptions(productSetOptions) {
    return set(state => ({
      ...state,
      productSetOptions,
    }));
  },
  materials: [],
  setMaterials(materials) {
    return set(state => ({
      ...state,
      materials,
    }));
  },
  addMaterials(materials) {
    return set(state => ({
      ...state,
      materials: state.materials.concat(materials),
    }));
  },
  presets: [],
  setPresets(presets) {
    return set(state => ({
      ...state,
      presets,
    }));
  },
  productSets: [],
  setProductSets(productSets) {
    return set(state => ({
      ...state,
      productSets,
    }));
  },
  orderSummaryColumn: [],
  setOrderSummaryColumn(orderSummaryColumn) {
    return set(state => ({
      ...state,
      orderSummaryColumn,
    }));
  },
  units: [],
  setUnits(units) {
    return set(state => ({
      ...state,
      units,
    }));
  },
  orderTypes: [],
  setOrderTypes(orderTypes) {
    return set(state => ({
      ...state,
      orderTypes,
    }));
  },
}));

export const useDefaultFilters = () => {
  const { user } = useAuthStore();
  const [teams, setTeams] = useState<undefined | TeamType[]>(undefined);
  const [orderStatus, setOrderStatus] = useState<undefined | orderStatusType[]>(
    undefined
  );
  const [orderTypes, setOrderTypes] = useState<undefined | orderStatusType[]>(
    undefined
  );
  useQuery(GET_TEAMS, {
    onCompleted(data) {
      setTeams(data.teams);
    },
  });

  useQuery(GET_ORDER_STATUS, {
    onCompleted(data) {
      setOrderStatus(data.workorderStatus);
    },
  });
  useQuery(GET_ORDER_TYPES, {
    onCompleted(data) {
      setOrderTypes(data.workorderType);
    },
  });

  const { setFilters } = useOrderStore();

  const savedFilters = localStorage.getItem("orderFilters");

  useEffect(() => {
    if (!teams || !orderStatus || !orderTypes) {
      return;
    }

    const defaultFilters: RecordFilter[] = [];

    const filteredTeams = teams.filter(
      team =>
        checkAuth([
          "order_view_all",
          {
            permission: "order_view_team",
            checkGroup: "userTeam",
            conditionGroup: [team.id],
          },
        ]) &&
        team.type?.name == "admin" &&
        team.inHouse
    );

    // Types
    const options_teams = filteredTeams?.map(t => ({
      name: t.name,
      value: t.id,
    }));

    const defaultValues_teams = filteredTeams?.map(t => t.id);

    const firstDay = new Date(new Date().getFullYear(), 0, 1);
    const lastDay = new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    );

    const rangeFilter: RecordFilter = {
      name: "Date",
      where: "updated_at",
      type: "range",
      rangeType: "date",
      values: {
        from: dayjs(firstDay).format("YYYY-MM-DD"),
        to: dayjs(lastDay).format("YYYY-MM-DD"),
      },
      defaultValues: {
        from: dayjs(firstDay).format("YYYY-MM-DD"),
        to: dayjs(lastDay).format("YYYY-MM-DD"),
      },
    };
    defaultFilters.push(rangeFilter);

    // const dueFilter: RecordFilter = {
    //   name: "Due",
    //   where: "due",
    //   type: "range",
    //   rangeType: "date",
    //   values: {
    //     from: dayjs(firstDay).format("YYYY-MM-DD"),
    //     to: dayjs(lastDay).format("YYYY-MM-DD"),
    //   },
    //   defaultValues: {
    //     from: dayjs(firstDay).format("YYYY-MM-DD"),
    //     to: dayjs(lastDay).format("YYYY-MM-DD"),
    //   },
    // };
    // defaultFilters.push(dueFilter);

    const defaultValues_status = orderStatus.map(os => os.id);
    const options_status = orderStatus.map(os => ({
      name: os.name,
      value: os.id,
    }));

    const statusFilter: RecordFilter = {
      name: "Status",
      where: "statusId",
      type: "in",
      values: defaultValues_status,
      defaultValues: defaultValues_status,
      options: options_status,
    };

    defaultFilters.push(statusFilter);

    const defaultValues_types = orderTypes.map(os => os.id);
    const options_types = orderTypes.map(os => ({
      name: os.name,
      value: os.id,
    }));

    const typeFilter: RecordFilter = {
      name: "Type",
      where: "typeId",
      type: "in",
      values: defaultValues_types,
      defaultValues: defaultValues_types,
      options: options_types,
    };

    defaultFilters.push(typeFilter);

    if (savedFilters) {
      const parsedFilters = JSON.parse(savedFilters);
      if (parsedFilters.length == defaultFilters.length) {
        setFilters(JSON.parse(savedFilters));
        return;
      }
    }

    setFilters(defaultFilters);
  }, [teams, orderStatus, orderTypes]);
};

export const refineFilters: (
  filters: RecordFilter[],
  user?: User
) => queryParams = (filters, user) => {
  if (!filters || !user) {
    return {
      where: { _and: [] },
      offset: 0,
    };
  }

  const where: any = { _and: [] };
  const order_by: object[] = [];
  const userSubTeams = user.team?.subTeams || [];

  for (const filter of filters) {
    // Filter
    switch (filter.type) {
      case "in":
        where._and.push({ [filter.where]: { _in: filter.values } });
        break;
      case "contains":
        where._and.push({
          _or: [
            { [filter.where]: { _contains: filter.values } },
            { [filter.where]: { _contained_in: filter.values } },
          ],
        });
        break;
      case "range":
        const { from, to } = filter.values;
        if (from && to) {
          where._and.push({
            [filter.where]: {
              _gte: dayjs(filter.values.from)
                .subtract(1, "day")
                .format("YYYY-MM-DD"),
              _lte: filter.values.to,
            },
          });
        }
        break;
    }
  }

  const createdBys: any = [{ createdBy: { _eq: user.id } }];

  if (checkAuth("order_view_team")) {
    createdBys.push({
      process: {
        createdUser: { teamID: { _in: [...userSubTeams, user.team?.id] } },
      },
    });
  }

  where._and.push({
    _or: createdBys,
  });

  return {
    where: where,
    order_by: order_by,
    offset: 0,
  };
};

export const useOrderStorePopulate = () => {
  const {
    setDeductions,
    setProductSetOptions,
    setMaterials,
    setPresets,
    setProductSets,
    setOrderSummaryColumn,
    setUnits,
    setOrderTypes,
  } = useOrderStore();

  const { loading } = useQuery(GET_DEDUCTIONS_NO_IMG, {
    onCompleted(data) {
      if (data.deductions) {
        setDeductions(data.deductions);
      }
    },
  });

  const { loading: optionsLoading } = useQuery(GET_PRODUCT_OPTIONS, {
    onCompleted(data) {
      if (data.productSetOptions) {
        setProductSetOptions(data.productSetOptions);
      }
    },
  });

  const { loading: matsLoading } = useQuery(GET_MATERIALS_MIN, {
    onCompleted(data) {
      if (data.materials) {
        setMaterials(data.materials);
      }
    },
  });

  const { loading: presetsLoading } = useQuery(GET_DEDUCTION_PRESETS, {
    onCompleted(data) {
      if (data.deductionPreset) {
        setPresets(
          sort(data.deductionPreset).by([
            { asc: p => p.productSet },
            { asc: p => p.optionCode },
            { asc: p => p.position },
          ])
        );
      }
    },
  });

  const { loading: productSetsLoading } = useQuery(GET_PRODUCT_SETS_MIN, {
    onCompleted(data) {
      if (data.productSets) {
        setProductSets(data.productSets);
      }
    },
  });

  const { loading: orderSummaryColumnLoading } = useQuery(
    GET_ORDER_SUMMARY_COLUMN,
    {
      onCompleted(data) {
        if (data.orderSummaryColumn) {
          setOrderSummaryColumn(data.orderSummaryColumn);
        }
      },
    }
  );

  const { loading: orderTypesLoading } = useQuery(GET_ORDER_TYPES, {
    onCompleted(data) {
      if (data.workorderType) {
        setOrderTypes(data.workorderType);
      }
    },
  });

  const { loading: unitsLoading } = useQuery(GET_UNITS, {
    onCompleted(data) {
      if (data.units) {
        setUnits(data.units);
      }
    },
  });

  const isLoading =
    loading ||
    optionsLoading ||
    matsLoading ||
    presetsLoading ||
    productSetsLoading ||
    orderSummaryColumnLoading ||
    orderTypesLoading ||
    unitsLoading;
  return isLoading;
};

import {
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { Button } from "flowbite-react";
import {
  DocumentDuplicateIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import Location from "./location";
import uuid from "react-uuid";
import { location } from "../../types";
import { createPortal } from "react-dom";
import { useScheme } from "../../../../../../store/schemeStore";
import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useState } from "react";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { useOrderStore } from "../../../../../../store/orderStore";
import { currencyFormat } from "../../../../../../utils/numberMethods";
import useGetBlindFabricCutCharge from "../useGetBlindFabricCutCharge";
import { useSub, useSubs } from "../../../../../../utils/pubsub/pubsub";
import {
  LOCATION_SELECT_ARGS,
  orderEvent,
  PRICE_UPDATE_ARGS,
} from "../../../../../../utils/pubsub/orderEventArgs";
import { useDebouncedCallback } from "../../../../../../utils/useDebounceCallback";
import isNullish from "../../../../../../utils/isNullish";

export default function Locations() {
  const { control } = useFormContext();

  const { fields, append, remove, swap } = useFieldArray({
    name: "locations",
    control,
  });

  const addRow = (location?: location) => {
    if (location) {
      return append(location);
    }
    append({
      name: "",
      hidden: false,
      checked: false,
      openings: [
        {
          name: "",
          hidden: false,
          options: [],
          note: "",
          items: [
            {
              id: uuid(),
              width: 0,
              height: 0,
              selected: false,
              options: [],
              calcs: [],
              note: "",
            },
          ],
        },
      ],
    });
  };

  // console.log("locations rendered");

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [theActive, setActive] = useState<Active | null>(null);

  const handleDragStart = ({ active }: { active: Active }) => {
    setActive(active);
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (over && active.id !== over?.id) {
      const activeIndex = fields.findIndex(({ id }) => id === active.id);
      const overIndex = fields.findIndex(({ id }) => id === over.id);
      swap(activeIndex, overIndex);
    }
    setActive(null);
  };

  const handleDragCancel = () => {
    setActive(null);
  };

  const { scheme } = useScheme();

  return (
    <div className="flex flex-col gap-2 relative">
      <section className="flex flex-col gap-2">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToWindowEdges]}
        >
          <SortableContext
            items={fields.map(field => field.id)}
            strategy={verticalListSortingStrategy}
          >
            {fields.map((item, index) => {
              return (
                <Location
                  id={item.id}
                  key={item.id}
                  index={index}
                  remove={() => remove(index)}
                  addRow={addRow}
                />
              );
            })}
          </SortableContext>
          {createPortal(
            <DragOverlay className={`${scheme == "dark" ? "dark" : ""}`}>
              {theActive?.data?.current?.index !== undefined && (
                <Location
                  id={theActive.id as string}
                  index={theActive.data.current.index as number}
                  remove={() => {
                    // @ts-expect-error
                    remove(theActive.data.current.index as number);
                  }}
                  addRow={addRow}
                  isOverlay={true}
                />
              )}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      </section>
      <div className="mt-2">
        <Button
          onClick={() => {
            addRow();
          }}
          color="purple"
          size="xs"
          outline
        >
          <PlusCircleIcon className="w-5 mr-2" />
          add unit
        </Button>
      </div>
      <LocationActions remove={remove} append={append} />
      <TotalPrice />
    </div>
  );
}

interface LocActionsProps {
  remove: UseFieldArrayRemove;
  append: UseFieldArrayAppend<FieldValues, "locations">;
}

const LocationActions = ({ remove, append }: LocActionsProps) => {
  const { scheme } = useScheme();

  const { getValues, setValue } = useFormContext();

  const [selected, setSelected] = useState<location[]>([]);

  useSub(orderEvent.LOCATION_SELECT, (_: LOCATION_SELECT_ARGS) => {
    const locations: location[] = getValues("locations");
    const selected = locations
      .map((l, i) => ({ ...l, index: i }))
      .filter(l => l.checked);

    setSelected(selected);
  });

  const _delete = () => {
    const selectedIndexes = selected
      .map(s => s.index)
      .filter(s => s != undefined);
    remove(selectedIndexes as number[]);
  };

  const uncheck = () => {
    for (const location of selected) {
      setValue(`locations.${location.index}.checked`, false);
    }
    setSelected([]);
  };

  const duplicate = () => {
    append(
      selected.map(l => ({
        ...l,
        index: undefined,
        checked: false,
        openings: l.openings.map(opening => ({
          ...opening,
          items: opening.items.map(item => ({ ...item, id: uuid() })),
        })),
      }))
    );
    uncheck();
  };

  if (selected.length < 1) {
    return null;
  }

  return (
    <>
      {createPortal(
        <div className={`${scheme}`}>
          <div className="absolute z-10 left-0 w-full bottom-0 px-4 py-2 flex flex-row gap-2 bg-dark text-white dark:text-black dark:bg-gray-50 border-t-2 border-plum">
            {/* delete */}
            <div
              className="flex flex-row gap-2 cursor-pointer"
              onClick={_delete}
            >
              <TrashIcon className="w-5 text-red-500" />
              <div>Delete {selected.length} Locations</div>
            </div>
            {/* duplicate */}
            <div
              className="flex flex-row gap-2 cursor-pointer"
              onClick={duplicate}
            >
              <DocumentDuplicateIcon className="w-5 text-grass" />
              <div>Duplicate {selected.length} Locations</div>
            </div>
          </div>
        </div>,
        document.body
      )}
    </>
  );
};

const TotalPrice = () => {
  const { getValues, control } = useFormContext();

  const { productSets } = useOrderStore();

  const [totalPrice, setTotalPrice] = useState(0);

  const getBlindCutFeeSurcharge = useGetBlindFabricCutCharge();

  const updateTotal = () => {
    const locations: location[] = getValues("locations");

    const newTotal = locations.reduce<number>((acc, cur) => {
      let locationTotal = 0;

      for (const opening of cur.openings) {
        const productSetId = Number(opening.product);
        const productSet = productSets.find(ps => ps.id == productSetId);
        const isCustom = opening.product == "custom";

        if (isCustom) {
          locationTotal +=
            Math.ceil(opening.price || 0) +
            Math.ceil(
              isNullish(opening?.customInstall)
                ? opening?.install || 0
                : opening.customInstall!!
            );
          continue;
        }
        if (!productSet) {
          continue;
        }
        if (productSet.hideItems) {
          locationTotal +=
            Math.ceil(opening.price || 0) +
            Math.ceil(
              isNullish(opening?.customInstall)
                ? opening?.install || 0
                : opening.customInstall!!
            );
          continue;
        }

        for (const item of opening.items) {
          locationTotal +=
            Math.ceil(item.price || 0) +
            Math.ceil(
              isNullish(item?.customInstall)
                ? item?.install || 0
                : item.customInstall!!
            );
        }
      }

      return acc + locationTotal;
    }, 0);

    const blindCutFeeSurCharge = getBlindCutFeeSurcharge(locations);

    const total = newTotal + blindCutFeeSurCharge;

    if (total !== totalPrice) {
      setTotalPrice(total);
    }
  };

  const debouncedUpdateTotal = useDebouncedCallback(updateTotal, 300);

  useSubs([
    {
      event: orderEvent.PRICE_UPDATE,
      callback: debouncedUpdateTotal,
    },
    { event: orderEvent.INSTALL_PARAM_UPDATE, callback: debouncedUpdateTotal },
  ]);

  const showPrice = useWatch({
    control,
    name: "showPrice",
    defaultValue: false,
  });

  return (
    <>
      {showPrice && (
        <div className="absolute right-2 top-2">
          Total Price: {currencyFormat(totalPrice)}
        </div>
      )}
    </>
  );
};

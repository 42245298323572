import { sort } from "fast-sort";
import { useOrderStore } from "../../../../../../store/orderStore";
import { ProductSetType } from "../../../types";
import { item, opening, option, salesParams } from "../../types";
import Column from "./column";
import isNullish from "../../../../../../utils/isNullish";

interface props {
  product: ProductSetType;
  opening: opening;
  item?: item;
  locationIndex: number | string;
  singleLocation: boolean;
  noPrice?: boolean;
  hasDiscounts?: boolean;
  separateInstall?: boolean;
  gstInclusive?: boolean;
  salesParams?: salesParams;
}

export default function SummaryItem({
  locationIndex,
  singleLocation,
  product,
  opening,
  item,
  noPrice,
  hasDiscounts,
  separateInstall,
  gstInclusive,
  salesParams,
}: props) {
  const isOpening = product.hideItems;

  const index = `${singleLocation ? "" : `${locationIndex}.`}${opening.index}${
    !isOpening ? `.${item?.index}` : ""
  }`;

  const width = isOpening
    ? opening.options?.find(o => o.name == "W")?.value
    : item?.width;
  const height = isOpening
    ? opening.options?.find(o => o.name == "H")?.value
    : item?.height;

  let sqm = Number(((width * height) / 1000000).toFixed(2));
  const trueSqm = sqm.toFixed(1);
  if (sqm < 0.5) {
    sqm = 0.5;
  }
  let price = isOpening ? opening.price || 0 : item?.price || 0;

  const install = isOpening
    ? isNullish(opening.customInstall)
      ? opening.install || 0
      : opening.customInstall || 0
    : isNullish(item?.customInstall)
    ? item?.install || 0
    : item?.customInstall || 0;

  const discount = isOpening
    ? opening.salesParams?.discount || 0
    : item?.salesParams?.discount || 0;

  const productDiscount = salesParams?.productParams?.find(
    p => p.productSetId == product.id
  )?.discount;

  const hasProductDisount = !isNullish(productDiscount?.value, true);

  let productDiscountDisplay = undefined as undefined | string;

  if (productDiscount?.value) {
    const isRate = productDiscount.type == "rate";

    let discountedDollars = isRate
      ? price / (1 - productDiscount.value / 100) - price
      : productDiscount.value;

    price = price + discountedDollars;

    if (gstInclusive) {
      discountedDollars = discountedDollars * 1.1;
    }
    const discountedDollarsStr = Number(discountedDollars)
      .toFixed(1)
      .replace(".0", "");

    if (isRate) {
      productDiscountDisplay = `${discountedDollarsStr}(${productDiscount.value}%)`;
    } else {
      productDiscountDisplay = discountedDollarsStr;
    }
  }

  const tdStyle = "px-2 font-semibold text-[9pt] text-center innerTd";

  const { orderSummaryColumn } = useOrderStore();

  const summaryColumns = sort(orderSummaryColumn).asc("position");

  const presetOptions = item?.options.filter(
    o => o.source == "deductionPreset" && !o.noCalc
  ) || [opening];

  const multipleConfigs = isOpening
    ? false
    : item &&
      item?.options.filter(o => o.source == "deductionPreset").length > 1;

  const rowSpan = isOpening ? 1 : presetOptions?.length || 1;

  const options = isOpening ? opening.options || [] : item?.options || [];

  return (
    <>
      {presetOptions?.map((preset, i) => (
        <tr key={i} className="h-[22px]">
          <td className="w-[10px] bg-[#c1aa80]" />
          {i == 0 && (
            <>
              <td
                rowSpan={rowSpan}
                className={`${tdStyle} text-center px-1 ${
                  multipleConfigs && "multiRow"
                }`}
              >
                {index}
              </td>
              <td
                rowSpan={rowSpan}
                className={`${tdStyle} ${multipleConfigs && "multiRow"}`}
              >
                {opening.name || ""}
              </td>
              <td
                rowSpan={rowSpan}
                colSpan={multipleConfigs ? 1 : 2}
                className={`${tdStyle} ${multipleConfigs && "multiRow"}`}
              >
                {product.name}
              </td>
            </>
          )}
          {multipleConfigs && <td className={tdStyle}>{preset.name}</td>}
          {i == 0 && (
            <>
              <td
                rowSpan={rowSpan}
                className={`${tdStyle} ${multipleConfigs && "multiRow"}`}
              >
                {width}
              </td>
              <td
                rowSpan={rowSpan}
                className={`${tdStyle} ${multipleConfigs && "multiRow"}`}
              >
                {height}
              </td>
            </>
          )}

          {summaryColumns.map(column => (
            <Column
              key={column.id}
              tdStyle={tdStyle}
              column={column}
              presetOptionsLength={presetOptions.length}
              preset={!isOpening ? (preset as option) : undefined}
              isOpening={isOpening}
              options={options}
            />
          ))}
          <td className={tdStyle} style={{ textAlign: "right" }}>
            {trueSqm}
          </td>
          {!noPrice && i == 0 && (
            <>
              <td
                rowSpan={rowSpan}
                className={`${tdStyle} ${multipleConfigs && "multiRow"}`}
                style={{ textAlign: "right" }}
              >
                ${Number(gstInclusive ? price * 1.1 : price).toFixed(2)}
              </td>
              {separateInstall && (
                <td
                  rowSpan={rowSpan}
                  className={`${tdStyle} ${multipleConfigs && "multiRow"}`}
                  style={{ textAlign: "right" }}
                >
                  $
                  {gstInclusive
                    ? Number(install * 1.1).toFixed(2)
                    : Number(install).toFixed(2)}
                </td>
              )}
              {hasDiscounts && (
                <td
                  rowSpan={rowSpan}
                  className={`${tdStyle} ${
                    multipleConfigs && "multiRow"
                  } text-red-500`}
                  style={{ textAlign: "right" }}
                >
                  {discount
                    ? `$${
                        gstInclusive
                          ? Number(discount * 1.1)
                              .toFixed(1)
                              .replace(".0", "")
                          : discount
                      }`
                    : ""}

                  {discount && hasProductDisount ? "/" : ""}
                  {hasProductDisount && productDiscountDisplay}
                </td>
              )}
            </>
          )}
          <td className="!w-[10px] bg-[#c1aa80]" />
        </tr>
      ))}
      {(!presetOptions || presetOptions.length < 1) && (
        <tr>
          <td className="w-[10px] bg-[#c1aa80]" />
          <td rowSpan={rowSpan} className={`${tdStyle} text-center px-1`}>
            {index}
          </td>
          <td rowSpan={rowSpan} className={tdStyle}>
            {opening.name || ""}
          </td>
          <td
            rowSpan={rowSpan}
            colSpan={multipleConfigs ? 1 : 2}
            className={tdStyle}
          >
            {product.name}
          </td>

          <td rowSpan={rowSpan} className={tdStyle}>
            {width}
          </td>
          <td rowSpan={rowSpan} className={tdStyle}>
            {height}
          </td>
          {summaryColumns.map(column => (
            <Column
              key={column.id}
              tdStyle={tdStyle}
              column={column}
              presetOptionsLength={0}
              preset={undefined}
              isOpening={isOpening}
              options={options}
            />
          ))}
          <td className={tdStyle}>{sqm.toFixed(1)}</td>
          {!noPrice && (
            <>
              <td className={tdStyle} style={{ textAlign: "right" }}>
                ${Number(gstInclusive ? price * 1.1 : price).toFixed(2)}
              </td>
              {separateInstall && (
                <td className={tdStyle} style={{ textAlign: "right" }}>
                  $
                  {gstInclusive
                    ? Number(install * 1.1).toFixed(2)
                    : Number(install).toFixed(2)}
                </td>
              )}
              {hasDiscounts && (
                <td
                  className={`${tdStyle} text-red-500`}
                  style={{ textAlign: "right" }}
                >
                  {discount
                    ? `$${
                        gstInclusive
                          ? Number(discount * 1.1)
                              .toFixed(1)
                              .replace(".0", "")
                          : discount
                      }`
                    : ""}
                  {discount && hasProductDisount ? "/" : ""}
                  {hasProductDisount && productDiscountDisplay}
                </td>
              )}
            </>
          )}
          <td className="!w-[10px] bg-[#c1aa80]" />
        </tr>
      )}
    </>
  );
}

import { TextInput } from "flowbite-react";
import { useFormContext, useWatch } from "react-hook-form";
import CustomQuoteOptions from "./customQuoteOptions";
import { usePub } from "../../../../../../../utils/pubsub/pubsub";
import {
  orderEvent,
  PRICE_UPDATE_ARGS,
} from "../../../../../../../utils/pubsub/orderEventArgs";
import { ChangeEventHandler } from "react";

interface props {
  openingCoord: string;
}

export default function CustomItem({ openingCoord }: props) {
  const { register, control, setValue } = useFormContext();

  const showPrice = useWatch({
    name: `showPrice`,
    control,
    exact: true,
  });

  const publish = usePub<PRICE_UPDATE_ARGS>();

  const publishPriceUpdate = () => {
    publish(orderEvent.PRICE_UPDATE, {
      orderEvent: orderEvent.PRICE_UPDATE,
    });
  };

  const myRegister = (coord: string) => {
    const onChange: ChangeEventHandler<
      HTMLInputElement | HTMLSelectElement
    > = e => {
      publishPriceUpdate();
      setValue(coord, e.target.value);
    };

    return { ...register(coord), onChange };
  };

  return (
    <>
      <TextInput
        sizing="sm"
        type="number"
        addon="W"
        className="w-24"
        {...register(`${openingCoord}.width`)}
      />
      <TextInput
        sizing="sm"
        type="number"
        addon="H"
        className="w-24"
        {...register(`${openingCoord}.height`)}
      />
      <TextInput
        sizing="sm"
        className="flex-1 min-w-[200px]"
        {...register(`${openingCoord}.customDesc`)}
        placeholder="Describe custom item"
      />
      {showPrice && (
        <>
          <CustomQuoteOptions coord={openingCoord} showPrice={showPrice} />
          <div className={`relative group/option w-20 my-[5px]`}>
            <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
              Install
            </div>
            <TextInput
              addon="$"
              className="w-full"
              {...myRegister(`${openingCoord}.install`)}
              sizing="sm"
            />
          </div>
          <div className={`relative group/option w-20 my-[5px]`}>
            <div className="left-[50%] -translate-x-[50%] -top-[8px] text-plum w-max leading-none absolute transition-all z-10 backdrop-blur-md rounded-md pointer-events-none">
              Price
            </div>
            <TextInput
              addon="$"
              className="w-full"
              {...myRegister(`${openingCoord}.price`)}
              sizing="sm"
            />
          </div>
        </>
      )}
    </>
  );
}

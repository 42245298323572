import { ListGroup } from "flowbite-react";
import { useFormContext, useWatch } from "react-hook-form";
import { option } from "../../../../../types";
import { useEffect, useRef, useState } from "react";
import { MaterialsType } from "../../../../../../../materials/types";
import { useIsMounted, useOnClickOutside } from "usehooks-ts";
import { MagnifyingGlassIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import { optionValues } from "../../../../../../types";
import { useOrderStore } from "../../../../../../../../../store/orderStore";
import isNullish from "../../../../../../../../../utils/isNullish";
import { sort } from "fast-sort";
import AddCustomMaterial from "../../addCustomMaterial";
import matDisplayName from "../../matDisplayName";
import { useUpdateSelectOptionValue } from "./updateValue";
import {
  OPTION_UPDATE_ARGS,
  orderEvent,
} from "../../../../../../../../../utils/pubsub/orderEventArgs";
import { useSub } from "../../../../../../../../../utils/pubsub/pubsub";

interface props {
  optionCoord: string;
  disabled?: boolean;
  formOptions: option[];
  optionValue: any;
  optionValues: option["values"];
  optionDefault: option["default"];
  optionName: string;
  openingId: string;
  itemId?: string;
  optionId: number;
  defaultValues: (string | number)[] | undefined;
}

export default function MaterialSelect({
  optionCoord,
  disabled,
  formOptions,
  optionValue,
  optionValues,
  optionDefault,
  optionName,
  openingId,
  itemId,
  optionId,
  defaultValues,
}: props) {
  const { getValues, setValue, control } = useFormContext();

  const optionCode = useWatch({
    name: `${optionCoord}.optionCode`,
    exact: true,
    control,
  });

  const presetID = useWatch({
    name: `${optionCoord}.presetID`,
    exact: true,
    control,
  });

  const { materials: cachedMaterials } = useOrderStore();
  const materials = cachedMaterials.filter(mat => {
    if (optionCode) {
      return mat.optionCode == optionCode;
    } else {
      return defaultValues?.includes(mat.id);
    }
  });

  const canAddCustom = useWatch({
    name: `${optionCoord}.allowCustomInput`,
    exact: true,
    control,
  });

  const nameScope: undefined | string[] = getValues(`${optionCoord}.nameScope`);

  useEffect(() => {
    if (!optionValue) {
      return;
    }

    const _mat = materials.find(
      d => d.id.toString() === optionValue?.toString()
    );

    const mat = {
      id: _mat?.id,
      name: _mat?.name,
      widthDeduction: _mat?.widthDeduction,
      heightDeduction: _mat?.heightDeduction,
      size: _mat?.size,
      brand: _mat?.brand,
      color: _mat?.color,
      params: _mat?.params,
    };

    const currentPopulatedValue: MaterialsType | undefined = getValues(
      `${optionCoord}.populatedValue`
    );

    if (JSON.stringify(mat) !== JSON.stringify(currentPopulatedValue)) {
      setValue(`${optionCoord}.populatedValue`, mat);
    }

    const dpValue = matDisplayName(mat, nameScope);

    const currentDisplayValue = getValues(`${optionCoord}.displayValue`);

    if (currentDisplayValue !== dpValue) {
      setValue(`${optionCoord}.displayValue`, dpValue);
    }
  }, [
    JSON.stringify(optionValue),
    sort(materials)
      .asc("id")
      .map(m => m.id)
      .join(", "),
  ]);

  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    if (disabled) {
      return;
    }
    if (!open) {
      setFilteredOptions(getFilteredOptionValuesByConditions());
    }
    setOpen(!open);
  };
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  const selectedMat = materials.find(
    d => d.id?.toString() == optionValue?.toString()
  );

  const getFilteredOptionValuesByConditions = () => {
    const materialsIdOnly = materials.map(mat => mat.id);

    const filered = materialsIdOnly
      ? materialsIdOnly.filter(o => {
          const material = materials.find(p => p.id.toString() == o.toString());
          if (!material) {
            return false;
          }

          const conditions = material?.optionCondition;

          const reducedConditions = conditions
            ?.filter(c => !c.reverse)
            .reduce<optionValues[]>((acc, cur) => {
              let optionToCheck = cur;

              if (optionToCheck.group) {
                const matchingFormOption = formOptions.find(
                  fo => fo.group == optionToCheck.group && fo.id == presetID
                );
                if (!matchingFormOption) return acc;
                optionToCheck = {
                  ...optionToCheck,
                  option: matchingFormOption?.id,
                };
              }

              if (acc.find(option => option.option == optionToCheck.option)) {
                return acc.map(ov => {
                  if (ov.option == optionToCheck.option) {
                    return {
                      ...ov,
                      values: ov.values.concat(optionToCheck.value),
                    };
                  } else {
                    return ov;
                  }
                });
              } else {
                return acc.concat({
                  option: optionToCheck.option,
                  values: [optionToCheck.value],
                });
              }
            }, []);

          const reverseConditions = conditions?.filter(c => c.reverse);

          if (
            reverseConditions &&
            reverseConditions.some(c => {
              const myOption = formOptions.find(o => {
                if (o.group) {
                  return o.group == c.group && o.presetID == presetID;
                }
                return o.id == Number(c.option);
              });
              return myOption?.value.toString() == c.value.toString();
            })
          ) {
            return false;
          }

          if (reducedConditions) {
            return reducedConditions.every(c => {
              const myOption = formOptions.find(o => o.id == Number(c.option));
              const values = c.values.map(v => v.toString());
              return values.includes(myOption?.value.toString());
            });
          }

          return true;
        })
      : [];

    return filered;
  };

  const [filteredOptions, setFilteredOptions] = useState<(string | number)[]>(
    optionValues || getFilteredOptionValuesByConditions()
  );

  useSub(
    orderEvent.OPTION_UPDATE,
    (args: OPTION_UPDATE_ARGS) => {
      if (
        args.itemId == itemId &&
        args.openingId == openingId &&
        args.optionId !== optionId
      ) {
        const newOptions = getFilteredOptionValuesByConditions();

        if (
          newOptions.length == filteredOptions.length &&
          newOptions.every(o => filteredOptions.includes(o))
        ) {
          return;
        }
        setFilteredOptions(newOptions);
      }
    },
    [openingId, itemId, formOptions]
  );

  const isMounted = useIsMounted();

  const updateValue = useUpdateSelectOptionValue(
    optionCoord,
    openingId,
    optionId,
    itemId
  );

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    setValue(`${optionCoord}.values`, filteredOptions);

    if (filteredOptions.length == 0) {
      if (optionValue !== "") {
        updateValue("");
      }

      return;
    }

    if (!filteredOptions.find(o => o.toString() == optionValue?.toString())) {
      const newVal: any =
        filteredOptions.find(o => o.toString() == optionDefault?.toString()) ||
        filteredOptions[0];

      let newValId = newVal
        ? materials.find(d => d.id.toString() == newVal.toString())?.id
        : "";

      const disableAutoSelect = getValues(`${optionCoord}.disableAutoSelect`);

      if (disableAutoSelect) {
        newValId = "";
      }

      const populatedVal = materials.find(mat => mat.id == Number(newValId));
      if (optionValue !== newValId?.toString()) {
        updateValue(newValId?.toString());

        const currentPopulatedValue = getValues(
          `${optionCoord}.populatedValue`
        );

        if (
          !currentPopulatedValue?.id ||
          currentPopulatedValue?.id !== populatedVal?.id
        ) {
          setValue(`${optionCoord}.populatedValue`, populatedVal);
        }
      }
    }
  }, [JSON.stringify(filteredOptions), isMounted]);

  const [search, setSearch] = useState("");

  const populatedOptions = filteredOptions
    ? (filteredOptions
        .map(o => materials.find(p => p.id.toString() == o.toString()))
        .filter(o => !isNullish(o)) as MaterialsType[])
    : ([] as MaterialsType[]);

  const sortedOptions = sort(populatedOptions)
    .asc(["brand", "name", "color", "size"])
    .filter(
      mat =>
        search.trim() == "" ||
        `${mat.name} ${mat.color}`.toLowerCase().includes(search.toLowerCase())
    );

  const [adding, setAdding] = useState(false);

  const onCustomMatAdd = (mat: MaterialsType) => {
    setAdding(false);
    setSearch("");
    updateValue(mat.id.toString());
  };

  const finalOptions = sortedOptions.filter(mat => {
    if (mat.pending || !mat.selectable) {
      return false;
    }
    return true;
  });

  return (
    <div className="relative" ref={ref}>
      <ListGroup
        className={`${disabled ? "brightness-90 dark:brightness-75" : ""}`}
        onClick={toggleOpen}
      >
        <ListGroup.Item>
          <div
            className={`text-xs ${
              optionValue == "" && "text-red-500 animate-pulse"
            }`}
          >
            {optionValue == ""
              ? "Required"
              : matDisplayName(selectedMat, nameScope)}
          </div>
        </ListGroup.Item>
      </ListGroup>
      {open && (
        <div className="absolute z-20 mt-1 pb-10">
          <div className="flex flex-col bg-white dark:bg-gray-700 rounded-md border-[1px] dark:border-gray-600">
            <div className="relative flex flex-row gap-1 items-center rounded-t-md p-2 backdrop-brightness-90 dark:backdrop-brightness-75">
              {search.trim() == "" && (
                <MagnifyingGlassIcon className="w-4 absolute left-4 text-gray-700 dark:text-gray-500" />
              )}
              <input
                value={search}
                onChange={e => {
                  setSearch(e.target.value);
                }}
                autoFocus
                className="bg-white dark:bg-gray-100 w-full rounded-md px-2 py-1 dark:text-dark text-xs"
              />
            </div>

            <div className="max-h-64 overflow-x-hidden overflow-y-auto flex flex-col gap-1 p-1 md:scrollbar-thin md:scrollbar-thumb-gray-300 md:dark:scrollbar-thumb-slate-700">
              {finalOptions.map(mat => {
                return (
                  <div
                    onClick={() => {
                      updateValue(mat.id);
                      setOpen(false);
                    }}
                    key={mat.id}
                    className="group/preset relative"
                  >
                    <div className="rounded-md px-4 py-2 cursor-pointer bg-white dark:bg-gray-700 hover:brightness-90 dark:hover:brightness-125 ring-gray-400 hover:ring-1 text-left min-w-max text-xs">
                      {matDisplayName(mat, nameScope)}
                    </div>
                  </div>
                );
              })}
              {finalOptions.length == 0 && optionCode && canAddCustom && (
                <div
                  onClick={() => {
                    setAdding(true);
                  }}
                  className="group/preset relative"
                >
                  <div className="flex flex-row gap-1 items-center rounded-md px-2 py-2 cursor-pointer bg-white dark:bg-gray-700 hover:brightness-90 dark:hover:brightness-125 ring-gray-400 hover:ring-1 text-left min-w-max text-xs">
                    <PlusCircleIcon className="w-5 text-grass" /> Add New
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {optionCode && (
        <AddCustomMaterial
          adding={adding}
          setAdding={setAdding}
          optionCode={optionCode}
          materials={populatedOptions}
          search={search}
          optionName={optionName}
          cb={onCustomMatAdd}
        />
      )}
    </div>
  );
}
